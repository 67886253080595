import { PageProps, graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import Hero from '../components/ChildPage/Hero';
import Banner from '../components/ChildPage/Banner';
import PromoVideo from '../components/TexansOfTexas/PromoVideo';
import GetToKnowSection from '../components/TexansOfTexas/GetToKnowSection';
import SEO from '../components/SEO';

import { ChildImageSharpType } from '../components/Interfaces/Generics';
import { TexasNode } from '../fragments/texas-node';

interface StringValue {
  value: string;
}

interface TexansOfTexasPage extends TexasNode {
  fieldDescription: StringValue;
  fieldGetToKnow: {
    entity: {
      fieldGetToKnowHeading: string;
      fieldGetToKnowSubHeading: string;
      fieldGetToKnowDescription: string;
      fieldGetToKnowImage: {
        alt: string;
        title: string;
        entity: {
          url: string;
          localFile: ChildImageSharpType;
        };
      }[];
    };
  }[];
  fieldOptionalFeaturedBanner?: {
    entity: {
      fieldFeaturedBannerHeading: string;
      fieldFeaturedBannerDescriptio: string;
      fieldFeaturedBannerLink: {
        title: string;
        url: {
          path: string;
        };
        uri: string;
      };
    };
  };
  fieldPromoVideo: {
    entity: {
      fieldYoutubeTitle: string;
      fieldBodyTextYoutube: {
        videoId: string;
        input: string;
      };
    };
  };
}

export interface TexansOfTexasData {
  drupal: {
    page: TexansOfTexasPage;
  };
}

export const query = graphql`
  query($id: String!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodeTexansOfTexas {
          fieldDescription {
            value
          }
          fieldGetToKnow {
            entity {
              ... on Drupal_ParagraphGetToKnow {
                fieldGetToKnowHeading
                fieldGetToKnowSubHeading
                fieldGetToKnowDescription
                fieldGetToKnowImage {
                  alt
                  title
                  entity {
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldOptionalFeaturedBanner {
            entity {
              ... on Drupal_ParagraphFeaturedBanner {
                fieldFeaturedBannerHeading
                fieldFeaturedBannerDescriptio
                fieldFeaturedBannerLink {
                  title
                  uri
                  url {
                    path
                  }
                }
              }
            }
          }
          fieldPromoVideo {
            entity {
              ... on Drupal_ParagraphPromoVideo {
                fieldYoutubeTitle
                fieldBodyTextYoutube {
                  videoId
                  input
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function TexansOfTexas({ data: { drupal } }: PageProps<TexansOfTexasData>) {
  const data = drupal.page;

  return (
    <Layout currentLanguage={data.langcode.value} translations={data.entityTranslations}>
      <SEO
        metatags={data.entityMetatags}
        language={data.langcode.value}
        breadCrumbInfo={{
          breadcrumb: data.entityUrl.breadcrumb,
          currentTitle: data.title,
          currentPath: data.entityUrl.path,
        }}
        entityTranslations={data.entityTranslations}
      />

      <Hero
        title={data.title}
        description={data.fieldDescription.value}
        breadcrumb={data.entityUrl.breadcrumb}
      />

      <PromoVideo
        video={data.fieldPromoVideo.entity.fieldBodyTextYoutube}
        videoTitle={data.fieldPromoVideo.entity.fieldYoutubeTitle}
      />

      <GetToKnowSection getToKnow={data.fieldGetToKnow} language={data.langcode.value} />

      {data.fieldOptionalFeaturedBanner && (
        <Banner
          title={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerHeading}
          description={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerDescriptio}
          linkTitle={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerLink.title}
          linkUrl={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerLink.url.path}
        />
      )}
    </Layout>
  );
}
