import React from 'react';
import { Link } from '../../Interfaces/Generics';
import LinksHelper from '../../utils/LinksHelper';

interface FormRelatedLinksProps {
  currentLanguage: string;
  links: Link[];
  className?: string;
}

export default function FormRelatedLinks(props: FormRelatedLinksProps) {
  return (
    <div className={`related-links ${props.className}`}>
      <section>
        <h2 className="related-links__title">
          {props.currentLanguage === 'en' ? 'Related links' : 'Enlaces relacionados'}
        </h2>
        <ul aria-label="Related links" className="related-links__link-list">
          {props.links.map((link, i) => (
            <li key={i}>
              <LinksHelper
                linkTitle={link.title}
                linkUrl={link.url.path}
                linkClassname="related-links__link"
              />
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
