import React from 'react';
import xss from 'xss';

import './Hero.scss';
import ButtonLink from '../utils/ButtonLink';
import Breadcrumbs from '../Generics/Breadcrumbs';
import { Breadcrumb } from '../../fragments/texas-node';

interface HeroProps {
  breadcrumb: Breadcrumb[];
  title: string;
  description: string;
  subDescription?: string;
  linkTitle?: string;
  linkUrl?: string;
  video?: {
    input: string;
    title?: string;
    text?: string;
  };
}

export default function Hero({
  breadcrumb,
  title,
  description,
  subDescription,
  linkTitle,
  linkUrl,
  video,
}: HeroProps) {
  return (
    <div className="wrapper wrapper__child">
      <div className="container container--column">
        <Breadcrumbs breadcrumb={breadcrumb} currentPageTitle={title} />
        <div className="hero-child">
          <div className={video ? 'hero-child__info-wrapper' : 'w-2/3'}>
            <h1 className="hero-child__title">{title}</h1>
            <div
              className="hero-child__description pb-5"
              dangerouslySetInnerHTML={{ __html: xss(description) }}
            />
            {subDescription && (
              <div
                className="hero-child__sub-description"
                dangerouslySetInnerHTML={{ __html: xss(subDescription) }}
              />
            )}
            {linkTitle && linkUrl && (
              <div className="pt-5">
                <ButtonLink url={linkUrl}>{linkTitle}</ButtonLink>
              </div>
            )}
          </div>
          {video && (
            <div className="hero-child__video-wrapper">
              <div className="hero-child__video">
                <iframe
                  width="100%"
                  height="100%"
                  src={video.input}
                  title={video.title}
                  aria-label={video.title ? video.title : 'Youtube Video'}
                />
              </div>
              <div className="hero-child__video-text">{video.text} </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
