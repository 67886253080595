import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { ChildImageSharpType } from '../Interfaces/Generics';
import AgencyCard from './AgencyCard';
import CheckMarkIcon from '../Icons/CheckMarkIcon';
import CloseIcon from '../Icons/CloseIcon';
import Pagination from '../Generics/Pagination';
import SearchBox from '../Generics/SearchBox';
import { search } from '../../search';
import {
  translateLanguage,
  displaySearchMessage,
  displayFilterMessage,
} from '../utils/UtilityFunctions';
import LoadingSpinner from '../Generics/LoadingSpinner';
import { LunrQueryOpts } from '../../lunr-query';

import './AgenciesDirectory.scss';

interface AgenciesCtx {
  language: string;
  pageNumber: number;
  limit: number;
  totalItems: number;
  path: string;
}

interface AgencyDirectoryProps {
  cards: AgencyCardProps[];
  filter: AgencyTypeProps[];
  pageContext: AgenciesCtx;
  currentLanguage: string;
}

interface AgencyCardProps {
  fieldLogo: {
    alt: string;
    url: string;
    title: string;
    height: number;
    width: number;
    entity: {
      localFile: ChildImageSharpType;
    };
  };
  entityId: string;
  entityLabel: string;
  fieldEmail: string;
  fieldPhone: string;
  fieldMailingAddress: {
    value: string;
  };

  fieldAgencyLink: {
    uri: string;
  };
  fieldAgencyType: {
    entity: {
      entityId: string;
      name: string;
    };
  }[];
  fieldServices: {
    entity: {
      name: string;
    };
  }[];
}
interface AgencyTypeProps {
  entityTranslation: {
    entityId: string;
    entityLabel: string;
    agencyOfType: {
      entities: {
        entityTranslation: {
          entityLabel: string;
        };
      }[];
    };
  };
}

export default function AgenciesDirectory({
  cards,
  filter,
  pageContext,
  currentLanguage,
}: AgencyDirectoryProps) {
  const [filterSelected, setFilterSelected] = useState<string[]>([]);
  const [filteredCards, setFilteredCards] = useState<any[]>(cards);
  const [showFilter, setShowFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [totalResults, setTotalResults] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, isLoading] = useState<boolean>(false);

  const language = pageContext.language.toLowerCase();

  const resultsStartRef = useRef() as MutableRefObject<HTMLDivElement>;

  const searchQuery: LunrQueryOpts[] = [
    { field: 'nodeType', query: 'Drupal_TaxonomyTermAgencies', required: true },
  ];

  if (searchTerm) {
    searchQuery.push({ query: searchTerm, required: true });
  }

  function runSearch(currentPage?: number) {
    return search(currentLanguage, searchQuery, {
      pageSize: pageContext.limit,
      currentPage: currentPage || pageNumber,
      filters: filterSelected,
    });
  }

  useEffect(() => {
    if (!searchTerm && !filterSelected.length) {
      setFilteredCards(cards);
    } else {
      isLoading(true);
      runSearch().then((res) => {
        isLoading(false);
        setTotalResults(res.totalResults);
        setFilteredCards(res.results);
        setPageNumber(1);
      });
    }
  }, [filterSelected, searchTerm]);

  const filterItems = filter.filter((e) => !!e.entityTranslation);
  filterItems.sort((a, b) =>
    a.entityTranslation.entityLabel > b.entityTranslation.entityLabel ? 1 : -1
  );
  filteredCards.sort((a, b) => (a.entityLabel > b.entityLabel ? 1 : -1));

  function handleFilterChange(filterType: string) {
    if (filterSelected.includes(filterType)) {
      setFilterSelected(filterSelected.filter((type) => type !== filterType));
    } else {
      setFilterSelected((filter) => [...filter, filterType]);
    }
  }

  function handleCloseFilter(filterType: string) {
    setFilterSelected(filterSelected.filter((type) => type !== filterType));
  }

  function handleClearFilter() {
    setFilterSelected([]);
  }

  const getSearchTerm = (term: string) => {
    setSearchTerm(term);
  };

  const usePagination = (n: number) => {
    runSearch(n)?.then((res) => {
      setPageNumber(n);
      setFilteredCards(res.results);
      setTotalResults(res.totalResults);
      if (resultsStartRef && resultsStartRef.current) {
        resultsStartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  };

  return (
    <section
      className="wrapper wrapper--another-grey agencies-wrapper"
      ref={resultsStartRef}
      id="results-start"
    >
      <div className="container">
        <div className="w-1/3 d-none-mobile">
          <div className="agencies-dept__filter-container">
            <div className="agencies-dept__filter-title">
              {translateLanguage(language, 'Filter agency type:', 'Filtra for tipo de agencia')}
            </div>
            {filterItems.map((item, i) => {
              return (
                <div key={i} className="agencies-dept__filter">
                  <div
                    className={
                      'agencies-dept__checkbox-container' +
                      (filterSelected.includes(item.entityTranslation.entityId) ? ' checked' : '')
                    }
                  >
                    {filterSelected.includes(item.entityTranslation.entityId) && (
                      <CheckMarkIcon className="check" />
                    )}
                    <input
                      className="agencies-dept__checkbox"
                      type="checkbox"
                      checked={filterSelected.includes(item.entityTranslation.entityId)}
                      onChange={() => handleFilterChange(item.entityTranslation.entityId)}
                      name={item.entityTranslation.entityLabel}
                      id={item.entityTranslation.entityLabel}
                      data-testid="filter-checkbox"
                    />
                  </div>

                  <label
                    className={
                      'agencies-dept__filter-type' +
                      (filterSelected.includes(item.entityTranslation.entityId) ? ' selected' : '')
                    }
                    htmlFor={item.entityTranslation.entityLabel}
                  >
                    {item.entityTranslation.entityLabel}
                  </label>
                </div>
              );
            })}
            <button
              className="agencies-dept__clear-filter"
              onClick={() => handleClearFilter()}
              aria-label={translateLanguage(
                language,
                'Clear all filters',
                'Borrar todos los filtros'
              )}
              data-testid="clear-all-filters"
            >
              {translateLanguage(language, 'Clear all filters', 'Borrar todos los filtros')}
            </button>
          </div>
        </div>

        <div className="w-2/3">
          <div className="agencies-dept__input-section">
            <SearchBox
              pageContextLanguage={pageContext.language}
              placeholder={translateLanguage(
                language,
                'Search for a state agency or department',
                'Buscar agencies estatales o departmentos'
              )}
              onSearch={getSearchTerm}
              language={language}
            />

            {showFilter && (
              <div className="agencies-dept__mobile-filter">
                <div className="agencies-dept__filter-title">
                  {translateLanguage(language, 'Filter agency type:', 'Filtra for tipo de agencia')}
                </div>
                {filterItems.map((item, i) => {
                  return (
                    <div key={i} className="agencies-dept__filter">
                      <div
                        className={
                          'agencies-dept__checkbox-container' +
                          (filterSelected.includes(item.entityTranslation.entityId)
                            ? ' checked'
                            : '')
                        }
                      >
                        {filterSelected.includes(item.entityTranslation.entityId) && (
                          <CheckMarkIcon className="check" />
                        )}
                        <input
                          className="agencies-dept__checkbox"
                          type="checkbox"
                          checked={filterSelected.includes(item.entityTranslation.entityId)}
                          onChange={() => handleFilterChange(item.entityTranslation.entityId)}
                          id={item.entityTranslation.entityLabel}
                          name={item.entityTranslation.entityLabel}
                          aria-label={item.entityTranslation.entityLabel}
                          data-testid="mobile-filter-checkbox"
                        />
                      </div>

                      <p
                        className={
                          'agencies-dept__filter-type' +
                          (filterSelected.includes(item.entityTranslation.entityId)
                            ? ' selected'
                            : '')
                        }
                      >
                        {item.entityTranslation.entityLabel}
                      </p>
                    </div>
                  );
                })}
                <button
                  className="agencies-dept__clear-filter"
                  onClick={() => handleClearFilter()}
                  aria-label={translateLanguage(
                    language,
                    'Clear all filters',
                    'Borrar todos los filtros'
                  )}
                  data-testid="mobile-clear-all-filters"
                >
                  {translateLanguage(language, 'Clear all filters', 'Borrar todos los filtros')}
                </button>
                <button
                  className="agencies-dept__open-close-filter"
                  onClick={() => setShowFilter(!showFilter)}
                  aria-label={translateLanguage(language, 'Close', 'Cerrar')}
                  data-testid="mobile-close-filters"
                >
                  {translateLanguage(language, 'Close', 'Cerrar')}
                </button>
              </div>
            )}

            {!showFilter && (
              <button
                className="agencies-dept__open-close-filter"
                onClick={() => setShowFilter(!showFilter)}
                aria-label={translateLanguage(language, 'Open filters', 'Abrir')}
                data-testid="mobile-filters"
              >
                {translateLanguage(language, 'Open filters', 'Abrir')}
              </button>
            )}

            <hr className="agencies-dept__divider" />
          </div>

          {(filterSelected.length > 0 || searchTerm) && (
            <div className="agencies-dept__filter-results">
              <div className="agencies-dept__display-message">
                {searchTerm && <h3>{displaySearchMessage(totalResults, searchTerm, language)}</h3>}
                {filterSelected.length > 0 && !searchTerm && (
                  <h3>{displayFilterMessage(totalResults, language)}</h3>
                )}
                {filteredCards.length === 0 && (
                  <p>
                    {translateLanguage(
                      language,
                      'Try clearing your filters or changing your search.',
                      'Intenta borrar filtros de busqueda, o cambiar una búsqueda nueva.'
                    )}
                  </p>
                )}
              </div>
              {filterSelected.length > 0 && (
                <div className="agencies-dept__filter-by">
                  <div className="agencies-dept__filter-by-title">
                    {translateLanguage(language, 'Filtering by:', 'Filtrado por:')}
                  </div>
                  {filterSelected.map((filter, i) => (
                    <div key={i} className="agencies-dept__filter-by-box">
                      {
                        filterItems.find((id) => id.entityTranslation.entityId === filter)
                          ?.entityTranslation.entityLabel
                      }
                      <button
                        type="button"
                        onClick={() => handleCloseFilter(filter)}
                        className="close-icon-wrapper"
                        aria-label={translateLanguage(
                          language,
                          'Clear Search',
                          'Borrar la Busqueda'
                        )}
                        data-testid="clear-filter"
                      >
                        <CloseIcon
                          title={translateLanguage(language, 'Clear Search', 'Borrar la Busqueda')}
                          className="close-icon"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {filteredCards.map((card, i) => (
            <AgencyCard
              key={i}
              title={card.entityLabel}
              address={card.fieldMailingAddress.value}
              phone={card.fieldPhone}
              email={card.fieldEmail}
              url={card.fieldAgencyLink.uri}
              logo={{
                alt: card.fieldLogo.alt,
                url: card.fieldLogo.entity.localFile.childImageSharp.fluid,
              }}
              currentLanguage={language}
            />
          ))}
          {filterSelected.length < 1 && !searchTerm ? (
            <Pagination
              currentLanguage={language}
              totalItems={pageContext.totalItems}
              pageSize={pageContext.limit}
              currentPage={pageContext.pageNumber}
              createLinkUrl={(n) =>
                n === 1
                  ? pageContext.path + '#results-start'
                  : `${pageContext.path}${n}#results-start`
              }
            />
          ) : (
            <Pagination
              currentLanguage={pageContext.language.toLowerCase()}
              totalItems={totalResults}
              pageSize={pageContext.limit}
              currentPage={pageNumber}
              createLinkUrl={() => ''}
              onClick={usePagination}
            />
          )}
        </div>
      </div>
      {loading && <LoadingSpinner />}
    </section>
  );
}
