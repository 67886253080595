import React from 'react';
import xss from 'xss';

import BackgroundImage from 'gatsby-background-image';
import { FixedObject, FluidObject } from '../Interfaces/Generics';
import Img from 'gatsby-image';

import './Hero.scss';

interface HeroProps {
  title: string;
  description: string;
  subDescription: string;
  background_photo: { fixed: FixedObject; fluid: FluidObject };
}

const heroBody = (props: HeroProps) => {
  return (
    <div className="hero-homepage">
      <h1>{props.title}</h1>
      <div
        className="hero-homepage__description"
        dangerouslySetInnerHTML={{ __html: xss(props.description) }}
      />
      <div className="label" dangerouslySetInnerHTML={{ __html: xss(props.subDescription) }} />
    </div>
  );
};

export default function Hero(props: HeroProps) {
  return (
    <>
      <BackgroundImage
        Tag="section"
        fluid={props.background_photo.fluid}
        className={'hero-homepage__background-image d-none-mobile'}
      >
        <div className="wrapper wrapper__transparent">
          <div className="container container--column">
            <div className="w-1/2">{heroBody(props)}</div>
          </div>
        </div>
      </BackgroundImage>

      <div className="d-none-desktop  wrapper__transparent">
        <Img fluid={props.background_photo.fluid} className="hero-homepage__image-mobile" />
        {heroBody(props)}
      </div>
    </>
  );
}
