import React from 'react';

import xss from 'xss';

//Utilities
import { transformToId } from '../../../../components/utils/UtilityFunctions';
import { formText } from './FormText';
import { useLocation } from '@reach/router';

import '../Form.scss';
import './FormSuccess.scss';

interface FormSuccessProps {
  formSuccess: {
    header: string;
    description: string;
  };
  currentLanguage: string;
}

export default function FormSuccess({ formSuccess, currentLanguage }: FormSuccessProps) {
  const location = useLocation();

  const htmlLocationContent = location?.pathname;
  const htmlDescriptionContent = formSuccess?.description;

  const sanitizedLocationContent = xss(htmlLocationContent);
  const sanitizedDescriptionContent = xss(htmlDescriptionContent);
  return (
    <div className="form-success">
      <section id={transformToId(formSuccess.header)}>
        <h2 className="form-success__label">{formSuccess.header}</h2>
        <p
          className="form-success__description"
          dangerouslySetInnerHTML={{ __html: sanitizedDescriptionContent }}
        />
      </section>

      <section>
        <div className="form-success__link-as-button">
          <a href={sanitizedLocationContent}>
            {currentLanguage === 'en'
              ? formText.en_new_form_button_label
              : formText.es_new_form_button_label}
          </a>
        </div>
      </section>
    </div>
  );
}
