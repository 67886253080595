import React from 'react';
import './ButtonLink.scss';

interface ButtonProps {
  url: string;
  children: string;
}

export default function ButtonLink({ url, children }: ButtonProps) {
  return (
    <a
      className={'button button-primary'}
      href={url}
      target={url.charAt(0) === '/' ? '' : '_blank'}
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
