import React from 'react';
import GetToKnow from './GetToKnow';
import { ChildImageSharpType } from '../Interfaces/Generics';

import './GetToKnow.scss';
import { translateLanguage } from '../utils/UtilityFunctions';

interface GetToKnowSectionProps {
  getToKnow: {
    entity: {
      fieldGetToKnowHeading: string;
      fieldGetToKnowSubHeading: string;
      fieldGetToKnowDescription: string;
      fieldGetToKnowImage: {
        alt: string;
        title: string;
        entity: {
          url: string;
          localFile: ChildImageSharpType;
        };
      }[];
    };
  }[];
  language: string;
}

export default function GetToKnowSection(props: GetToKnowSectionProps) {
  return (
    <div className="get-to-know">
      <div className="get-to-know__container">
        <div className="get-to-know__header">
          <h2 className="h1">
            {translateLanguage(
              props.language,
              'Get to know some local Texans',
              'Conoce Algunos Tejanos'
            )}
          </h2>
        </div>
        {props.getToKnow.map((item, i) => (
          <GetToKnow
            key={i}
            heading={item.entity.fieldGetToKnowHeading}
            sub_heading={item.entity.fieldGetToKnowSubHeading}
            description={item.entity.fieldGetToKnowDescription}
            imageSrc={item.entity.fieldGetToKnowImage}
          />
        ))}
      </div>
    </div>
  );
}
