import { PageProps, graphql } from 'gatsby';
import React from 'react';

import { TexasNode } from '../fragments/texas-node';
import Hero from '../components/Directory/Hero';
import Layout from '../components/Layout';
import ServicesDirectory from '../components/Directory/ServicesDirectory';
import SEO from '../components/SEO';
import { cleanPaginatedMetatags } from '../components/Directory/util';

export interface Service {
  entityTranslation: {
    entityLabel: string;
    fieldServiceType: {
      entity: {
        entityId: string;
      };
    }[];
    fieldServiceLink: {
      url: {
        path: string;
      };
      title: string;
    };
    agencyOfService: {
      entities: {
        entityTranslation: {
          entityLabel: string;
        };
      }[];
    };
  };
}

export interface ServiceType {
  entityTranslation: {
    entityId: string;
    entityLabel: string;
    serviceOfType: {
      entities: {
        entityTranslation: {
          entityLabel: string;
        };
      }[];
    };
  };
}

export interface ServicesPage extends TexasNode {
  body: {
    value: string;
  };
}

export interface ServicesData {
  drupal: {
    page: ServicesPage;
    services: {
      entities: Service[];
    };
    serviceTypes: {
      entities: ServiceType[];
    };
  };
}

export const query = graphql`
  query($id: String!, $offset: Int!, $limit: Int!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodeServicesPage {
          body {
            value
          }
        }
      }

      services: taxonomyTermQuery(
        limit: $limit
        offset: $offset
        filter: { conditions: [{ field: "vid", value: "services", operator: EQUAL }] }
        sort: { field: "name", direction: ASC, language: $language }
      ) {
        entities {
          entityTranslation(language: $language) {
            entityLabel
            ... on Drupal_TaxonomyTermServices {
              fieldServiceType {
                entity {
                  entityId
                }
              }
              agencyOfService: reverseFieldServicesTaxonomyTerm(
                filter: { conditions: { field: "vid", value: "agencies", operator: EQUAL } }
                limit: 1000
              ) {
                entities {
                  entityTranslation(language: EN) {
                    entityLabel
                  }
                }
              }
              fieldServiceLink {
                url {
                  path
                }
                title
              }
            }
          }
        }
      }

      serviceTypes: taxonomyTermQuery(
        filter: { conditions: [{ field: "vid", value: "service_type", operator: EQUAL }] }
        limit: 1000
      ) {
        entities {
          entityTranslation(language: $language) {
            ... on Drupal_TaxonomyTermServiceType {
              entityId
              entityLabel
              servicesOfType: reverseFieldServiceTypeTaxonomyTerm(
                filter: { conditions: { field: "vid", value: "services", operator: EQUAL } }
              ) {
                entities {
                  entityTranslation(language: $language) {
                    entityLabel
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface ServicesCtx {
  id: string;
  offset: number;
  pageNumber: number;
  limit: number;
  language: string;
  totalItems: number;
}

export default function Services({
  data: {
    drupal: { page, services, serviceTypes },
  },
  pageContext,
}: PageProps<ServicesData, ServicesCtx>) {
  const metaTags = cleanPaginatedMetatags(page.entityMetatags, pageContext.pageNumber);
  return (
    <Layout currentLanguage={page.langcode.value} translations={page.entityTranslations}>
      <SEO
        metatags={metaTags}
        language={page.langcode.value}
        entityTranslations={page.entityTranslations}
      />

      <Hero title={page.title} description={page.body.value} language={page.langcode.value} />

      <ServicesDirectory
        cards={services.entities}
        filter={serviceTypes.entities}
        pageContext={{
          ...pageContext,
          path: page.entityUrl.path,
        }}
        currentLanguage={page.langcode.value}
      />
    </Layout>
  );
}
