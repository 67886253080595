import React from 'react';

interface IconProps {
  className?: string;
}

export default function PlusIcon(props: IconProps) {
  return (
    <svg {...props} role="img" viewBox="0 0 20 20" className={props.className}>
      <title>Plus Icon</title>
      <path
        d="M9,7 L15,7 C15.5522847,7 16,7.44771525 16,8 C16,8.55228475 15.5522847,9 15,9 L9,9 L9,15 C9,15.5522847 8.55228475,16 8,16 C7.44771525,16 7,15.5522847 7,15 L7,9 L1,9 C0.44771525,9 0,8.55228475 0,8 C0,7.44771525 0.44771525,7 1,7 L7,7 L7,1 C7,0.44771525 7.44771525,0 8,0 C8.55228475,0 9,0.44771525 9,1 L9,7 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
