import React from 'react';
import { PageProps, graphql } from 'gatsby';

import Layout from '../components/Layout';
import ContactUsHero from '../components/ContactUs/ContactUsHero';
import ContactUsContainer from '../components/ContactUs/ContactUsContainer';
import SEO from '../components/SEO';
import { TexasNode } from '../fragments/texas-node';
import { transformToId } from '../components/utils/UtilityFunctions';

export interface StringValue {
  value: string;
}

interface FieldFormLabel {
  entity: {
    fieldFormDescription: StringValue;
    fieldFormHeader: string;
  };
}

interface FieldFormSuccess {
  entity: {
    fieldFormSuccessHeader: string;
    fieldFormSuccessMessage: StringValue;
  };
}

interface ContactUs extends TexasNode {
  fieldHeroDescription: string;
  fieldBodyDescription: StringValue;
  fieldRelatedLinks: {
    title: string;
    uri: string;
    url: {
      path: string;
    };
  }[];
  fieldFormLabel: FieldFormLabel[];
  fieldFormSuccess: FieldFormSuccess;
}

interface ContactUsData {
  drupal: {
    page: ContactUs;
  };
}

export const query = graphql`
  query($id: String!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodeContactUs {
          fieldBodyDescription {
            value
          }
          fieldHeroDescription
          fieldRelatedLinks {
            url {
              path
            }
            title
          }
          fieldFormLabel {
            entity {
              ... on Drupal_ParagraphFormTextBlock {
                fieldFormDescription {
                  value
                }
                fieldFormHeader
              }
            }
          }
          fieldFormSuccess {
            entity {
              ... on Drupal_ParagraphFormSuccessMessage {
                id
                fieldFormSuccessMessage {
                  value
                }
                fieldFormSuccessHeader
              }
            }
          }
        }
      }
    }
  }
`;

export default function ContactUs({ data: { drupal } }: PageProps<ContactUsData>) {
  const data = drupal.page;
  const bodyTextCxt = data.fieldFormLabel || [];

  const sidebarAnchors = bodyTextCxt.map((item) => {
    return {
      title: item.entity.fieldFormHeader,
      url: '#' + transformToId(item.entity.fieldFormHeader),
    };
  });

  const formInformation =
    data.fieldFormLabel &&
    data.fieldFormLabel.map((item: FieldFormLabel) => {
      return {
        header: item.entity.fieldFormHeader,
        description: item.entity.fieldFormDescription.value,
      };
    });

  const formSuccess = {
    header: data.fieldFormSuccess.entity.fieldFormSuccessHeader,
    description: data.fieldFormSuccess.entity.fieldFormSuccessMessage.value,
  };

  return (
    <Layout currentLanguage={data.langcode.value} translations={data.entityTranslations}>
      <SEO
        metatags={data.entityMetatags}
        language={data.langcode.value}
        breadCrumbInfo={{
          breadcrumb: data.entityUrl.breadcrumb,
          currentPath: data.entityUrl.path,
          currentTitle: data.title,
        }}
        entityTranslations={data.entityTranslations}
      />
      <ContactUsHero
        title={data.title}
        description={data.fieldHeroDescription}
        breadcrumb={data.entityUrl.breadcrumb}
      />
      <div className="wrapper wrapper--white">
        <div className="container">
          <ContactUsContainer
            bodyText={data.fieldBodyDescription.value}
            currentLanguage={data.langcode.value}
            sidebarAnchors={sidebarAnchors}
            formInformation={formInformation}
            relatedLinks={data.fieldRelatedLinks}
            formSuccess={formSuccess}
          />
        </div>
      </div>
    </Layout>
  );
}
