import React from 'react';

interface IconProps {
  className?: string;
}

export default function TexasIcon(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby="texas-icon"
      role="img"
      className={props.className}
      viewBox="0 0 228.83 64.34"
    >
      <title id="texas-icon">Texas.gov Logo</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="#304ffe"
            d="M82.68,45.45c-3.83,0-5.55-2.11-5.42-6.34l.31-11.93L74.49,27l.13-1.72,3-.71L77.79,20h2.6l-.13,4.71H87l-.13,2.42h-6.7l-.31,12.07c-.09,2.55.84,3.79,3,3.79a10.09,10.09,0,0,0,3.88-1l.49,2.38a10.86,10.86,0,0,1-4.5,1.06"
          />
          <path
            fill="#304ffe"
            d="M92,35c.09,4.54,2.82,7.8,7.05,7.8a10.82,10.82,0,0,0,5.85-1.68l.53,2.51a12.38,12.38,0,0,1-6.48,1.72c-5.81,0-9.73-4.36-9.73-10.48,0-6.34,4.05-10.83,9.51-10.83,4.45,0,7.36,2.91,7.36,7.44a12.16,12.16,0,0,1-.44,3.22L92,34.81V35Zm11.36-2.43a6.48,6.48,0,0,0,0-.88c0-3.08-1.67-5.2-4.84-5.2-3.61,0-6,2.77-6.39,6.12Z"
          />
          <polygon
            fill="#304ffe"
            points="122.2 44.79 116.75 36.29 111.33 44.79 108.25 44.79 115.2 34.44 108.69 24.62 111.72 24.62 116.75 32.46 121.67 24.62 124.72 24.62 118.28 34.35 125.25 44.79 122.2 44.79"
          />
          <path
            fill="#304ffe"
            d="M140,42.37a7.87,7.87,0,0,1-5.9,3c-3.7,0-6.39-2.38-6.39-5.59,0-3.66,2.86-6.65,12.24-7.18V31.4c0-3.17-1.41-4.8-4.76-4.8a12.28,12.28,0,0,0-6.6,2.42L128,26.47a14.22,14.22,0,0,1,7.49-2.42c4.67,0,7.27,2.11,7.22,7.22V41c0,1.45.26,2.07,2,2l-.18,2.24c-2.64.18-4.1-.88-4.4-2.86H140Zm0-7.93c-6.91.79-9.38,2.34-9.38,5,0,2.16,1.76,3.52,4.14,3.52A7.15,7.15,0,0,0,140,40.34Z"
          />
          <path
            fill="#304ffe"
            d="M153.68,45.36a12.79,12.79,0,0,1-6.61-1.81l.75-2.51a11,11,0,0,0,5.94,1.85c2.42,0,4.54-1.19,4.54-3.39,0-2.42-2.55-3.13-5.07-3.92-2.77-.83-5.85-2.2-5.85-5.72s3-5.81,6.83-5.81a13.83,13.83,0,0,1,6.12,1.5L159.54,28a11.93,11.93,0,0,0-5.5-1.5c-2.47,0-4,1.15-4,3.08,0,2.29,2.38,3,5,3.79,2.86.84,5.94,2.16,5.94,5.9s-3.18,6.07-7.23,6.07"
          />
          <path
            fill="#304ffe"
            d="M165.94,45.36a2.25,2.25,0,0,1-2.2-2.29,2.2,2.2,0,1,1,4.4,0,2.26,2.26,0,0,1-2.2,2.29"
          />
          <path
            fill="#304ffe"
            d="M172,41.62c0-1.67,1.46-3.13,3.31-3.83a7,7,0,0,1-3.26-6.17c0-4.27,3.17-7.58,7.93-7.58a9.52,9.52,0,0,1,4.76,1.19l5.59-.44.13,2.73L186,27.08a7,7,0,0,1,1.63,4.54c0,4.27-3.35,7.4-7.84,7.4a8.73,8.73,0,0,1-3-.57c-1.06.44-2.25,1.28-2.25,2.42s1,2.07,4.58,2.07h2.6c5.86,0,8.37,2,8.37,5.28,0,4.45-5,7.49-10.62,7.49-5.41,0-9.11-2.38-9.11-5.9a5.39,5.39,0,0,1,4.36-5C173,44.22,172,43.07,172,41.62m1,7.71c0,2.38,2.6,4.14,6.87,4.14s7.57-2,7.57-4.76c0-2.38-2.11-3.22-6.12-3.22-1.36,0-3.08,0-4.23-.13-2.41.66-4.09,2.25-4.09,4m6.92-12.51c3.08,0,5-2.2,5-5.28s-1.76-5.24-5.15-5.24c-3.13,0-5.07,2.25-5.07,5.37a4.91,4.91,0,0,0,5.2,5.15"
          />
          <path
            fill="#304ffe"
            d="M200.49,45.36c-5.6,0-9.74-4.14-9.74-10.61s4.32-10.7,9.95-10.7,9.69,4.14,9.69,10.61-4.3,10.7-9.9,10.7m.09-18.84c-4.27,0-7.09,3.39-7.09,8.15,0,4.93,2.86,8.24,7.14,8.24s7.09-3.39,7.09-8.15c0-4.94-2.87-8.24-7.14-8.24"
          />
          <polygon
            fill="#304ffe"
            points="220.91 44.84 218.44 44.84 210.47 24.62 213.43 24.62 219.81 41.27 225.97 24.62 228.84 24.62 220.91 44.84"
          />
          <path
            fill="#fff"
            d="M64.34,48.27V16.08C64.31,10.49,61,0,48.11,0H16.22C10.58,0,0,3.32,0,16.08V48.27c0,5.58,3.35,16,16.22,16.07H48.11c5.65,0,16.21-3.32,16.23-16.07"
          />
          <rect fill="#FF1944" x="2.32" y="2.32" width="59.7" height="59.7" rx="13.49" />
          <path
            fill="#fff"
            d="M50.61,31.89c-.11-.86-1.14-1.46-1.39-2.41-.41-1.59,0-3-.07-4.79a.82.82,0,0,0-.74-.77c-1-.14-1.56-1.07-2.61-1.27-1.91-.38-9.64-.45-10.14-.51a11.54,11.54,0,0,1-3.31-1.51A.78.78,0,0,1,32,20V14.59a.81.81,0,0,0-.81-.81H23.24V27.63a.81.81,0,0,1-.81.81H12.18c-.24,0-.37.13-.2.37.78,1.12,1.67,2.94,2.9,3.73,1,.61,2.09.87,2.67,1.77.71,1.11.64,2.76,1.65,3.8a7.2,7.2,0,0,0,3.13,1.58.8.8,0,0,0,.94-.31c.56-.88.78-2,1.76-2.28,3.6-1.07,4.18,1.92,5.32,4.06.88,1.65,2.65,2.55,3.17,3.93.25.67.07,1.73.25,2.28.65,2,2.84,2.16,4.76,2.72a.82.82,0,0,0,1-.89c-.34-2.63-.45-4.51.93-6.27a3.58,3.58,0,0,1,.92-.78c.85-.57,2-1,3-1.63,1.82-1.06,2.59-2.62,4.35-3.08a1.29,1.29,0,0,0,.78-.8c.32-1.69,1.25-3.22,1.07-4.75"
          />
        </g>
      </g>
    </svg>
  );
}
