import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

//Components
import FormBodyText from './FormBodyText';
import Form from './Form';
import Recaptcha from './FormComponents/Recaptcha';
import FormSuccess from './FormComponents/FormSuccess';
import { TxAlertType } from 'texkit-ui/components';

//Utilities
import { FormSendMail } from './FormComponents/FormSendMail';
import { FormValidationSchema, initialFormValues, FormFields } from './FormComponents/FormSchemas';

//Typescript Interfaces
interface FormContainerProps {
  bodyText: string;
  currentLanguage: string;
  setHideSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  formInformation: {
    header: string;
    description: string;
  }[];
  formSuccess: {
    header: string;
    description: string;
  };
}

export default function FormContainer({
  bodyText,
  formInformation,
  currentLanguage,
  formSuccess,
  setHideSidebar,
}: FormContainerProps) {
  const [token, setToken] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [mailError, setMailError] = useState<boolean>(false);
  const [multipleError, setMultipleError] = useState<boolean>(false);
  const [errorCounter, setErrorCounter] = useState<number>(0);

  const RECAPTCHA_ID = process.env.GATSBY_RECAPTCHA_ID!;
  const uniqueIdentifier = uuidv4().replace(/-/g, '');

  useEffect(() => {
    formik.setFieldValue('token', token);
    formik.setFieldValue('uniqueIdentifier', uniqueIdentifier);
    formik.setFieldValue('locale', currentLanguage);
    // Generate new captcha token every 100 seconds
    const interval = setInterval(() => {
      reloadRecaptcha(RECAPTCHA_ID);
    }, 110000);

    return () => clearInterval(interval);
  }, [token]);

  const reloadRecaptcha = async (RECAPTCHA_ID: string) => {
    await window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(RECAPTCHA_ID, { action: 'formPage' }).then((token) => {
        return setToken(token);
      });
    });
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: FormValidationSchema(currentLanguage),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values: FormFields) => {
      //Submit email and wait for response
      const res = await FormSendMail(values);

      //Set state of success/failure based on response from email submission
      if (res === 200) {
        setSuccess(true);
        setHideSidebar(true);
      } else if (res === 401 && errorCounter < 2) {
        // Generate new token in case of error
        reloadRecaptcha(RECAPTCHA_ID);

        setErrorCounter(errorCounter + 1);
        setSuccess(false);
        setMailError(true);
      } else {
        // Generate new token in case of error
        reloadRecaptcha(RECAPTCHA_ID);

        setErrorCounter(0);
        setSuccess(false);
        setMailError(false);
        setMultipleError(true);
        formik.resetForm({});
      }
    },
  });

  return (
    <>
      {success ? (
        <FormSuccess currentLanguage={currentLanguage} formSuccess={formSuccess} />
      ) : mailError ? (
        <>
          <FormBodyText bodyText={bodyText} />
          <Form
            currentLanguage={currentLanguage}
            formik={formik}
            formInformation={formInformation}
            emailError={mailError}
            type={TxAlertType.Warning}
          />
        </>
      ) : multipleError ? (
        <>
          <FormBodyText bodyText={bodyText} />
          <Form
            currentLanguage={currentLanguage}
            formik={formik}
            formInformation={formInformation}
            emailError={multipleError}
            type={TxAlertType.Error}
          />
        </>
      ) : (
        <>
          <FormBodyText bodyText={bodyText} />
          <Form
            currentLanguage={currentLanguage}
            formik={formik}
            formInformation={formInformation}
            emailError={false}
            type={undefined}
          />
        </>
      )}
      <Recaptcha sitekey={RECAPTCHA_ID} setToken={setToken} />
    </>
  );
}
