import React from 'react';
import Img from 'gatsby-image';

import './AgencyResources.scss';

interface FluidObject {
  aspectRatio: number;
  base64: string;
  originalImg: string;
  originalName: string;
  presentationHeight: number;
  presentationWidth: number;
  sizes: string;
  src: string;
  srcSet: string;
  srcSetWebp: string;
  srcWebp: string;
  tracedSVG: string;
}

interface AgencyResourcesProps {
  cards?: AgencyCardProps[];
  title?: string;
}

interface AgencyCardProps {
  logo: FluidObject;
  alt: string;
  name: string;
  services: string[];
}

function AgencyResourcesCard({ logo, alt, name, services }: AgencyCardProps) {
  return (
    <div className="agency-resources-card w-1/3">
      <Img className="agency-resources-card__image" fluid={logo} alt={alt} />

      <h3 className="agency-resources-card__title mb-0">{name}</h3>

      <ul className="agency-resources-card__list">
        {services.map((item, index) => (
          <li key={index} className="agency-resources-card__list-items">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function AgencyResources(props: AgencyResourcesProps) {
  return (
    <div className="wrapper wrapper--white">
      <div className="container container--column">
        {props.title && (
          <section className="agency-resources__header w-2/3">
            <h2 className="agency-resources__title mt-0">{props.title}</h2>
          </section>
        )}

        {props.cards && (
          <section className="agency-resources__card-container">
            {props.cards.map((card, index) => (
              <AgencyResourcesCard key={index} {...card} />
            ))}
          </section>
        )}
      </div>
    </div>
  );
}
