import React from 'react';
import xss from 'xss';

import './AgencyCard.scss';
import LinksHelper from '../utils/LinksHelper';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image';
import { translateLanguage } from '../utils/UtilityFunctions';

interface AgencyCardProps {
  title: string;
  email: string;
  address: string;
  phone: string;
  url: string;
  logo: {
    alt: string;
    url: FluidObject;
  };
  currentLanguage: string;
}

export default function AgencyCard({
  title,
  email,
  address,
  phone,
  url,
  logo,
  currentLanguage,
}: AgencyCardProps) {
  return (
    <div className="agency-card__card-design">
      <div className="container">
        <div className="w-1/2">
          <Img className="agency-card__logo" fluid={logo.url} alt={logo.alt} />
          <div className="agency-card__align-title-link">
            <LinksHelper linkTitle={title} linkUrl={url} linkClassname="agency-card__link" />
          </div>
        </div>
        <div className="agency-card__info-section w-1/2">
          <div className="agency-card__info-group">
            <p className="agency-card__sub-title">
              {translateLanguage(currentLanguage, 'Mailing Address', 'Dirección')}
            </p>
            <div className="agency-card__info" dangerouslySetInnerHTML={{ __html: xss(address) }} />
          </div>
          <div className="agency-card__info-group">
            <p className="agency-card__sub-title">
              {translateLanguage(currentLanguage, 'Phone', 'Numero de Teléfono')}
            </p>
            {phone && (
              <a href={'tel:' + phone} className="agency-card__info">
                {phone}
              </a>
            )}
          </div>
          <div className="agency-card__info-group">
            <p className="agency-card__sub-title">Email</p>
            {email && (
              <a href={'mailto:' + email} className="agency-card__info">
                {email}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
