import React, { useEffect } from 'react';

declare global {
  interface Window {
    grecaptcha:Enterprise;
    captchaOnLoad: () => void;
  }
}

interface Enterprise {
  enterprise: ReCaptchaInstance;
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => void;
  execute: (sitekey: string, options: ReCaptchaExecuteOptions) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: 'invisible';
}

interface RecaptchaProps {
  sitekey: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
}

export default function Recaptcha({ sitekey, setToken }: RecaptchaProps) {
  const loadRecaptcha = async () => {
    await window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(sitekey, { action: 'formPage' }).then((token) => {
        return setToken(token);
      });
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;
    script.addEventListener('load', loadRecaptcha);
    document.body.appendChild(script);
  }, []);

  return <div className="g-recaptcha" data-sitekey={sitekey} data-size="invisible" />;
}
