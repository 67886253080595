import React, {
  PropsWithChildren,
  isValidElement,
  useState,
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

export enum PatternChar {
  Any = '*',
  Number = '9',
  Letter = 'A',
}

const patternChars = Object.values(PatternChar) as string[];

const RegExps = {
  Letters: /^[a-z]/i,
  Numbers: /^[0-9]/i,
};

export function format(value: string, pattern: string) {
  const cleanedValue = value.replace(/[^a-z0-9]/gi, ''); // remove all special chars
  const chars = cleanedValue.split('');

  let count = 0;
  let formatted = '';

  for (var i = 0; i < pattern.length; i++) {
    const patternChar = pattern[i];
    const char = chars[count];

    if (char && patternChar) {
      if (patternChar === PatternChar.Any) {
        // Any letter or number
        formatted += char;
        count++;
      } else if (patternChar === PatternChar.Number) {
        // Numbers only
        if (RegExps.Numbers.test(char)) {
          formatted += char;
        }

        count++;
      } else if (patternChar === PatternChar.Letter) {
        // Letters only
        if (RegExps.Letters.test(char)) {
          formatted += char;
        }

        count++;
      } else {
        formatted += patternChar;
      }
    }
  }

  return formatted;
}

export interface TxInputMaskProps {
  mask: string;
  value: string;
  onChange?: (val: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Simple input mask that accepts a user defined pattern and reformats any text entered into it.
 *
 * Use "A" to represent a letter
 * Use "9" to represent a number
 * Use "*" to represent either a letter OR a number
 *
 * Example: A-9999_****
 *
 * When using TxInputMask with a forms library like Formik or react-hook-forms
 * you should pass the "value" and "onChange" props to the input mask itself and everything else to the wrapped input
 */
export function TxInputMask({
  children,
  onChange,
  value,
  mask,
}: PropsWithChildren<TxInputMaskProps>) {
  const [prevState, setPrevState] = useState('');
  const [state, setState] = useState('');
  const [selectionStart, setSelectionStart] = useState<number>(0);
  const ref = useRef<HTMLInputElement>(null);

  function internalOnChange(e: ChangeEvent<HTMLInputElement>) {
    const selectionStart = e.target.selectionStart;
    const value = format(e.target.value, mask);

    if (selectionStart !== null) {
      setSelectionStart(selectionStart); // track the previous selection start
    }

    setPrevState(state); // store previous value
    setState(value); // store new formatted value

    if (onChange) {
      onChange(e);
    }
  }

  // The selection range needs to be set after React is done rendering
  useLayoutEffect(() => {
    const offset = state.length - prevState.length; // offset lets you find out how many charcter have been added from the defined pattern

    if (ref.current) {
      const maskChar = mask[selectionStart - 1];
      // check if the current value is not a space for characters and has an offset greater then 0
      if (maskChar && !patternChars.includes(maskChar) && offset > 0) {
        ref.current.setSelectionRange(selectionStart + offset, selectionStart + offset);
      } else {
        ref.current.setSelectionRange(selectionStart, selectionStart);
      }
    }
  }, [state, prevState, selectionStart]);

  // prevent entering information if an invalid character
  useEffect(() => {
    function onKeydown(e: KeyboardEvent) {
      const offset = state.length - prevState.length; // offset lets you find out how many charcter have been added from the defined pattern

      const patternChar = offset < 0 ? mask[selectionStart] : mask[selectionStart - offset];

      if (e.key.length === 1 && /^[a-z0-9]/i.test(e.key) && patternChar) {
        if (state.length >= mask.length) {
          e.preventDefault();
        } else if (patternChar === PatternChar.Number) {
          if (!RegExps.Numbers.test(e.key)) {
            e.preventDefault();
          }
        } else if (patternChar === PatternChar.Letter) {
          // Letters only
          if (!RegExps.Letters.test(e.key)) {
            e.preventDefault();
          }
        }
      }
    }

    ref.current!.addEventListener('keydown', onKeydown);

    return () => {
      ref.current!.removeEventListener('keydown', onKeydown);
    };
  }, [ref, selectionStart, mask, state, prevState]);

  // make sure that value is formatted when new prop is passed
  useEffect(() => {
    if (value) {
      setState(format(value, mask));
    } else {
      setState(value);
    }
  }, [value]);

  if (isValidElement(children)) {
    return React.cloneElement(children, { value: state, onChange: internalOnChange, ref });
  }

  return <></>;
}
