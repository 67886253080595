import * as Yup from 'yup';
import { errorMessage } from './FormText';

export interface FormFields {
  uniqueIdentifier: string;
  name: string;
  email: string;
  phone: string;
  contactCode: string | '';
  txgovService: string;
  issue: string;
  locale: string;
  token: string;
}

export const initialFormValues: FormFields = {
  uniqueIdentifier: '',
  name: '',
  email: '',
  phone: '',
  contactCode: '',
  txgovService: '',
  issue: '',
  locale: '',
  token: '',
};

export const FormValidationSchema = (currentLanguage: string) =>
  Yup.object({
    name: Yup.string()
      .min(1, currentLanguage === 'en' ? errorMessage.en_name_min : errorMessage.es_name_min)
      .max(63, currentLanguage === 'en' ? errorMessage.en_name_max : errorMessage.es_name_max)
      .required(currentLanguage === 'en' ? errorMessage.en_required : errorMessage.es_required)
      .matches(
        /^[a-zA-ZÀ-ÿ-' ]+$/g,
        currentLanguage === 'en' ? errorMessage.en_name_allowed : errorMessage.es_name_allowed
      ),
    email: Yup.string()
      .max(55, currentLanguage === 'en' ? errorMessage.en_email_max : errorMessage.es_email_max)
      .email(currentLanguage === 'en' ? errorMessage.en_email : errorMessage.es_email)
      .matches(
        /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        currentLanguage === 'en' ? errorMessage.en_email : errorMessage.es_email
      )
      .required(currentLanguage === 'en' ? errorMessage.en_required : errorMessage.es_required),
    phone: Yup.string()
      .min(12, currentLanguage === 'en' ? errorMessage.en_min_phone : errorMessage.es_min_phone)
      .max(12, currentLanguage === 'en' ? errorMessage.en_max_phone : errorMessage.es_max_phone)
      .notRequired(),
    contactCode: Yup.string().required(
      currentLanguage === 'en' ? errorMessage.en_required : errorMessage.es_required
    ),
    txgovService: Yup.string()
      .min(
        1,
        currentLanguage === 'en'
          ? errorMessage.en_max_service_textarea
          : errorMessage.es_max_service_textarea
      )
      .max(
        250,
        currentLanguage === 'en'
          ? errorMessage.en_max_service_textarea
          : errorMessage.es_max_service_textarea
      )
      .required(currentLanguage === 'en' ? errorMessage.en_required : errorMessage.es_required),
    issue: Yup.string()
      .min(
        1,
        currentLanguage === 'en'
          ? errorMessage.en_max_description_textarea
          : errorMessage.es_max_description_textarea
      )
      .max(
        500,
        currentLanguage === 'en'
          ? errorMessage.en_max_description_textarea
          : errorMessage.es_max_description_textarea
      )
      .required(currentLanguage === 'en' ? errorMessage.en_required : errorMessage.es_required),
  });
