import React from 'react';

import './ServiceCard.scss';

import LinksHelper from '../utils/LinksHelper';
import { translateLanguage } from '../utils/UtilityFunctions';

interface ServiceCardProps {
  title: string;
  agencies: string[];
  path: string;
  currentLanguage: string;
}

export default function ServiceCard({ title, agencies, path, currentLanguage }: ServiceCardProps) {
  return (
    <div className="service-card__card-design">
      <div className="container">
        <div className="service-card__align-title-link">
          <LinksHelper linkTitle={title} linkUrl={path} linkClassname="service-card__link" />
        </div>

        <div className="service-card__agency">
          <p className="service-card__sub-title">
            {translateLanguage(currentLanguage, 'Agency: ', 'Agencia: ')}
          </p>

          {agencies.length > 0 ? (
            agencies.map((agency) => (
              <p className="service-card__agency-name" key={agency}>
                {agency}
              </p>
            ))
          ) : (
            <p className="service-card__agency-name">N/A</p>
          )}
        </div>
      </div>
    </div>
  );
}
