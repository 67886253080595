import React from 'react';
import Breadcrumbs from '../Generics/Breadcrumbs';
import { Breadcrumb } from '../../fragments/texas-node';

import './ContactUsHero.scss';

interface ContactUsHeroProps {
  breadcrumb: Breadcrumb[];
  title: string;
  description: string;
}

export default function ContactUsHero({ breadcrumb, title, description }: ContactUsHeroProps) {
  return (
    <div className="wrapper wrapper__child">
      <div className="container container--column">
        <Breadcrumbs breadcrumb={breadcrumb} currentPageTitle={title} />
        <div className="contact-us-hero-child">
          <div className="w-2/3">
            <h1 className="contact-us-hero-child__title">{title}</h1>
            <p className="contact-us-hero-child__description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
