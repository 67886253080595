import { Link } from 'gatsby';
import React from 'react';

import { SearchResult, DrupalNodeType } from '../../search';
import SearchIcon from '../Icons/SearchIcon';

import styles from './NavQuickSearchResults.module.scss';

export interface NavQuickSearchResultsProps {
  results: SearchResult[];
}

function createLink(result: SearchResult): JSX.Element {
  switch (result.__typename) {
    case DrupalNodeType.Agency:
      return (
        <a href={result.fieldAgencyLink.uri} target="_blank" rel="noreferrer">
          <SearchIcon className={styles.searchIcon} />

          <span>{result.entityLabel}</span>
        </a>
      );

    case DrupalNodeType.Service:
      return (
        <a href={result.fieldServiceLink.url.path} target="_blank" rel="noreferrer">
          <SearchIcon className={styles.searchIcon} />

          <span>{result.entityLabel}</span>
        </a>
      );
    case DrupalNodeType.County:
      return (
        <Link to={'/living-in-texas/property-tax-directory?search=' + result.entityLabel}>
          <SearchIcon className={styles.searchIcon} />

          <span>{result.entityLabel}</span>
        </Link>
      );

    case DrupalNodeType.ChildPage:
    case DrupalNodeType.MainLandingPage:
    case DrupalNodeType.TexansOfTexas:
    default:
      return (
        <Link to={result.entityUrl.path}>
          <SearchIcon className={styles.searchIcon} />

          <span>{result.entityLabel}</span>
        </Link>
      );
  }
}

export default function NavQuickSearchResults({ results }: NavQuickSearchResultsProps) {
  return (
    <ul className={styles.quickSearchResults}>
      {results.map((result, i) => (
        <li key={i}>{createLink(result)}</li>
      ))}
    </ul>
  );
}
