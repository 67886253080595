import React, { useState, useEffect } from 'react';
import './CookiesBanner.scss';
import CloseIcon from './Icons/CloseIcon';
import { CreateCookie, ReadCookie } from '../components/ManageCookie';

export default function CookiesBanner() {
  const [showCookiesBanner, setShowCookiesBanner] = useState(false);
  const closeCookiesBanner = () => setShowCookiesBanner(false);

  useEffect(() => {
    const cookie = ReadCookie('acceptCookie');
    if (cookie == null) {
      setShowCookiesBanner(true);
      CreateCookie('acceptCookie', 'texasGov', 120);
    }
  }, []);

  return (
    <>
      {showCookiesBanner && (
        <div data-testid="cookies-banner">
          <div className="cookies-banner">
            <div className="cookies-banner__close d-none-mobile">
              <button
                data-testid="desktop-close-button"
                type="button"
                onClick={closeCookiesBanner}
                className="cookies-banner__close-icon-wrapper"
                aria-label="Close"
              >
                <CloseIcon title="Close Banner" className="cookies-banner__close-icon" />
              </button>
            </div>
            <div className="cookies-banner__description">
              <p>
                The site uses cookies to provide you with a great user experience. By using
                Texas.gov, you accept our use of cookies. For more detail, view our Site Policies.
              </p>
            </div>
            <div className="cookies-banner__close d-none-desktop w-1/3">
              <button
                type="button"
                onClick={closeCookiesBanner}
                className="cookies-banner__close-icon-wrapper"
                aria-label="Close"
              >
                <CloseIcon title="Close Banner" className="cookies-banner__close-icon" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
