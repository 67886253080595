import { PageProps, graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import BodyText from '../components/ChildPage/BodyText';
import Hero from '../components/ChildPage/Hero';
import Banner from '../components/ChildPage/Banner';
import OnThisPageSidebar from '../components/ChildPage/OnThisPageSidebar';
import RelatedLinks from '../components/ChildPage/RelatedLinks';
import SEO from '../components/SEO';
import { transformToId } from '../components/utils/UtilityFunctions';
import { ChildImageSharpType } from '../components/Interfaces/Generics';
import { TexasNode } from '../fragments/texas-node';

import './ChildPage.scss';

interface StringValue {
  value: string;
}

interface FieldBodyText {
  fieldBodyTextContent: StringValue;
  fieldBodyTextLinks: {
    url: {
      path: string;
    };
    uri: string;
    title: string;
  }[];
  fieldBodyTextCtaPrimary: {
    url: {
      path: string;
    };
    uri: string;
    title: string;
  };
  fieldBodyTextCtaSecondary: {
    url: {
      path: string;
    };
    uri: string;
    title: string;
  };
  fieldBodyTextHeading: string;
  fieldBodyTextImage?: {
    alt: string;
    height: number;
    title: string;
    url: string;
    width: number;
    entity: {
      url: string;
      localFile: ChildImageSharpType;
    };
  };
  fieldBodyTextYoutube: {
    input: string;
    videoId: string;
  };
  fieldBodyTextYoutubeText: string;
  fieldYoutubeTitle: string;
  fieldBodyTextAccordion: {
    entity: {
      fieldAccordionContent: StringValue;
      fieldAccordionLabel: string;
    };
  }[];
}

interface ChildPage extends TexasNode {
  fieldDescription: StringValue;
  fieldSubDescription: StringValue;
  fieldRelatedLinks: {
    title: string;
    uri: string;
    url: {
      path: string;
    };
  }[];
  fieldLinkToService: {
    title: string;
    uri: string;
    url: {
      path: string;
    };
  };
  fieldHeroYoutubeVideo?: {
    entity: {
      fieldYoutubeVideoLink: {
        input: string;
      };
      fieldYoutubeVideoText?: string;
      fieldYoutubeVideoTitle?: string;
    };
  };
  fieldOptionalFeaturedBanner?: {
    entity: {
      fieldFeaturedBannerHeading: string;
      fieldFeaturedBannerDescriptio: string;
      fieldFeaturedBannerLink: {
        url: {
          path: string;
        };
        uri: string;
        title: string;
      };
    };
  };
  fieldBodyText: {
    entity: FieldBodyText;
  }[];
}

interface ChildPageData {
  drupal: {
    page: ChildPage;
  };
}

export const query = graphql`
  query($id: String!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodeChildPage {
          fieldDescription {
            value
          }
          fieldSubDescription {
            value
          }
          fieldRelatedLinks {
            title
            uri
            url {
              path
            }
          }
          fieldLinkToService {
            title
            uri
            url {
              path
            }
          }
          fieldHeroYoutubeVideo {
            entity {
              ... on Drupal_ParagraphHeroYoutubeVideo {
                fieldYoutubeVideoLink {
                  input
                }
                fieldYoutubeVideoText
                fieldYoutubeVideoTitle
              }
            }
          }
          fieldOptionalFeaturedBanner {
            entity {
              ... on Drupal_ParagraphFeaturedBanner {
                id
                fieldFeaturedBannerHeading
                fieldFeaturedBannerDescriptio
                fieldFeaturedBannerLink {
                  url {
                    path
                  }
                  uri
                  title
                }
              }
            }
          }
          fieldBodyText {
            entity {
              ... on Drupal_ParagraphBodyText {
                fieldBodyTextContent {
                  value
                }
                fieldBodyTextLinks {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldBodyTextCtaPrimary {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldBodyTextCtaSecondary {
                  url {
                    path
                  }
                  uri
                  title
                }
                fieldBodyTextHeading
                fieldBodyTextImage {
                  alt
                  height
                  title
                  width
                  entity {
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                fieldBodyTextYoutube {
                  input
                  videoId
                }
                fieldBodyTextYoutubeText
                fieldYoutubeTitle
                fieldBodyTextAccordion {
                  entity {
                    ... on Drupal_ParagraphAccordion {
                      fieldAccordionContent {
                        value
                      }
                      fieldAccordionLabel
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function createBodyText({ entity }: { entity: FieldBodyText }, index: number) {
  let image;

  if (entity.fieldBodyTextImage) {
    if (entity.fieldBodyTextImage.entity.localFile.childImageSharp) {
      image = {
        uri: entity.fieldBodyTextImage.entity.localFile.childImageSharp.fluid,
        alt: entity.fieldBodyTextImage.alt,
      };
    }
    if (
      !entity.fieldBodyTextImage.entity.localFile.childImageSharp &&
      entity.fieldBodyTextImage.entity.url
    ) {
      image = {
        url: entity.fieldBodyTextImage.entity.url,
        alt: entity.fieldBodyTextImage.alt,
      };
    }
  }

  return (
    <BodyText
      key={index}
      accordion={entity.fieldBodyTextAccordion?.map(({ entity }) => ({
        content: entity.fieldAccordionContent.value,
        label: entity.fieldAccordionLabel,
      }))}
      heading={entity.fieldBodyTextHeading}
      sectionId={transformToId(entity.fieldBodyTextHeading)}
      bodyText={entity.fieldBodyTextContent.value}
      ctaPrimary={entity.fieldBodyTextCtaPrimary}
      ctaSecondary={entity.fieldBodyTextCtaSecondary}
      links={entity.fieldBodyTextLinks}
      video={entity.fieldBodyTextYoutube}
      videoText={entity.fieldBodyTextYoutubeText}
      videoTitle={entity.fieldYoutubeTitle}
      image={image}
    />
  );
}

export default function ChildPage({ data: { drupal } }: PageProps<ChildPageData>) {
  const data = drupal.page;
  const bodyTextCxt = data.fieldBodyText || [];

  const sidebarAnchors = bodyTextCxt.map((item) => {
    return {
      title: item.entity.fieldBodyTextHeading,
      url: '#' + transformToId(item.entity.fieldBodyTextHeading),
    };
  });

  return (
    <Layout currentLanguage={data.langcode.value} translations={data.entityTranslations}>
      <SEO
        metatags={data.entityMetatags}
        language={data.langcode.value}
        breadCrumbInfo={{
          breadcrumb: data.entityUrl.breadcrumb,
          currentPath: data.entityUrl.path,
          currentTitle: data.title,
        }}
        entityTranslations={data.entityTranslations}
      />

      <Hero
        title={data.title}
        description={data.fieldDescription.value}
        subDescription={data.fieldSubDescription?.value}
        linkTitle={data.fieldLinkToService?.title}
        linkUrl={data.fieldLinkToService?.url?.path}
        breadcrumb={data.entityUrl.breadcrumb}
        {...(data.fieldHeroYoutubeVideo &&
          data.fieldHeroYoutubeVideo && {
            video: {
              input: data.fieldHeroYoutubeVideo.entity.fieldYoutubeVideoLink.input,
              text: data.fieldHeroYoutubeVideo.entity.fieldYoutubeVideoText,
              title: data.fieldHeroYoutubeVideo.entity.fieldYoutubeVideoTitle,
            },
          })}
      />

      {data.fieldOptionalFeaturedBanner && (
        <Banner
          title={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerHeading}
          description={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerDescriptio}
          linkTitle={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerLink.title}
          linkUrl={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerLink.url?.path}
        />
      )}

      <div className="wrapper wrapper--white">
        <div className="container">
          <div className="w-1/3">
            <div className="child-sidebar-sticky">
              {sidebarAnchors.length > 1 && (
                <OnThisPageSidebar currentLanguage={data.langcode.value} links={sidebarAnchors} />
              )}

              {data.fieldRelatedLinks.length > 0 && (
                <RelatedLinks
                  currentLanguage={data.langcode.value}
                  className="related-links__visible"
                  links={data.fieldRelatedLinks}
                />
              )}
            </div>
          </div>

          <div className="w-2/3">{bodyTextCxt.map(createBodyText)}</div>

          {data.fieldRelatedLinks.length > 0 && (
            <RelatedLinks
              currentLanguage={data.langcode.value}
              className="related-links__not-visible"
              links={data.fieldRelatedLinks}
            />
          )}
        </div>
      </div>
    </Layout>
  );
}
