import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/MainLanding/Hero';
import QuickLinks from '../components/MainLanding/QuickLinks';
import FeaturedPost from '../components/MainLanding/FeaturedPost';
import FeaturedBanner from '../components/MainLanding/FeaturedBanner';
import TopicsMenu from '../components/MainLanding/TopicsMenu';
import AgencyResources from '../components/MainLanding/AgencyResources';
import SEO from '../components/SEO';
import { ChildImageSharpType, Link } from '../components/Interfaces/Generics';
import { TexasNode } from '../fragments/texas-node';

interface StringValue {
  value: string;
}

interface MainLandingPage extends TexasNode {
  fieldDescription: StringValue;
  fieldQuickLinksDesc: StringValue;
  fieldPopularTopics: Link[];
  fieldRelatedAgencies?: Link[];
  fieldAgencyResourcesHeading?: StringValue;
  fieldTopicsMenuHeading: string;
  fieldTopicsMenuDescription: StringValue;
  fieldCallToActionLink?: Link;

  fieldFeaturedPost: {
    entity: {
      fieldFeaturedPostHeading: string;
      fieldFeaturedPostDescription: string;
      fieldFeaturedPostLink: {
        title: string;
        uri: string;
        url: {
          path: string;
        };
      };
      fieldFeaturedPostImage?: {
        alt: string;
        height: number;
        width: number;
        title: string;
        url: string;
        entity: {
          localFile: ChildImageSharpType;
        };
      };
      fieldImageCaption?: {
        processed: string;
      };
    };
  };

  fieldTopicMenuCard: {
    entity: {
      fieldTopicMenuCardHeading: string;
      fieldTopicMenuCardDescriptio: {
        value: string;
      };
      fieldTopicMenuCardLink: {
        title: string;
        uri: string;
        url: {
          path: string;
        };
      };
    };
  }[];

  fieldAgencyResourceCard?: {
    entity: {
      fieldAgencyLogo: {
        alt: string;
        height: number;
        width: number;
        title: string;
        url: string;
        entity: {
          localFile: ChildImageSharpType;
        };
      };
      fieldAgencyName: string;
      fieldAgencyResourceService: string[];
    };
  }[];

  fieldOptionalFeaturedBanner?: {
    entity: {
      fieldFeaturedBannerHeading: string;
      fieldFeaturedBannerDescriptio?: string;
      fieldFeaturedBannerLink: {
        title: string;
        uri: string;
        url: {
          path: string;
        };
      };
    };
  };

  fieldOptionalFeaturedPost?: {
    entity: {
      fieldOptionalFeaturedPostHea: string;
      fieldOpFeaturedPostDescripti: string;
      fieldOpFeaturedPostLink: {
        uri: string;
        url: {
          path: string;
        };
        title: string;
      };
      fieldOpFeaturedPostImg?: {
        alt: string;
        url: string;
        title: string;
        height: number;
        width: number;
        entity: {
          localFile: ChildImageSharpType;
        };
      };
    };
  };
}

export interface MainLandingPageData {
  drupal: {
    page: MainLandingPage;
  };
}

export const query = graphql`
  query($id: String!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodeMainLandingContentType {
          fieldDescription {
            value
          }
          fieldCallToActionLink {
            url {
              path
            }
            title
            uri
          }
          fieldQuickLinksDesc {
            value
          }
          fieldPopularTopics {
            url {
              path
            }
            title
            uri
          }
          fieldRelatedAgencies {
            url {
              path
            }
            uri
            title
          }
          fieldFeaturedPost {
            entity {
              ... on Drupal_ParagraphFeaturedPost {
                fieldFeaturedPostHeading
                fieldFeaturedPostDescription
                fieldFeaturedPostLink {
                  title
                  uri
                  url {
                    path
                  }
                }
                fieldFeaturedPostImage {
                  alt
                  height
                  width
                  title
                  entity {
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                fieldImageCaption {
                  processed
                }
              }
            }
          }
          fieldTopicsMenuHeading
          fieldTopicsMenuDescription {
            value
          }
          fieldTopicMenuCard {
            entity {
              ... on Drupal_ParagraphTopicMenuCard {
                fieldTopicMenuCardHeading
                fieldTopicMenuCardDescriptio {
                  value
                }
                fieldTopicMenuCardLink {
                  title
                  uri
                  url {
                    path
                  }
                }
              }
            }
          }
          fieldOptionalFeaturedBanner {
            entity {
              ... on Drupal_ParagraphFeaturedBanner {
                fieldFeaturedBannerHeading
                fieldFeaturedBannerDescriptio
                fieldFeaturedBannerLink {
                  title
                  uri
                  url {
                    path
                  }
                }
              }
            }
          }
          fieldOptionalFeaturedPost {
            entity {
              ... on Drupal_ParagraphOptionalFeaturedPost {
                fieldOptionalFeaturedPostHea
                fieldOpFeaturedPostDescripti
                fieldOpFeaturedPostLink {
                  uri
                  url {
                    path
                  }
                  title
                }
                fieldOpFeaturedPostImg {
                  alt
                  url
                  title
                  height
                  width
                  entity {
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          fieldAgencyResourcesHeading {
            value
          }
          fieldAgencyResourceCard {
            entity {
              ... on Drupal_ParagraphAgencyResourceCard {
                fieldAgencyLogo {
                  alt
                  height
                  width
                  title
                  entity {
                    url
                    localFile {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                fieldAgencyName
                fieldAgencyResourceService
              }
            }
          }
        }
      }
    }
  }
`;

export default function MainLandingPage({ data: { drupal } }: PageProps<MainLandingPageData>) {
  const data = drupal.page;

  let featuredPostImageProps;
  let optionalFeaturedPostImageProps;

  if (data.fieldFeaturedPost.entity.fieldFeaturedPostImage) {
    featuredPostImageProps = {
      alt: data.fieldFeaturedPost.entity.fieldFeaturedPostImage.alt,
      url:
        data.fieldFeaturedPost.entity.fieldFeaturedPostImage.entity.localFile.childImageSharp.fluid,
    };
  }

  if (
    data.fieldOptionalFeaturedPost &&
    data.fieldOptionalFeaturedPost.entity.fieldOpFeaturedPostImg
  ) {
    optionalFeaturedPostImageProps = {
      alt: data.fieldOptionalFeaturedPost.entity.fieldOpFeaturedPostImg?.alt,
      url:
        data.fieldOptionalFeaturedPost.entity.fieldOpFeaturedPostImg?.entity.localFile
          .childImageSharp.fluid,
    };
  }

  return (
    <Layout currentLanguage={data.langcode.value} translations={data.entityTranslations}>
      <SEO
        metatags={data.entityMetatags}
        language={data.langcode.value}
        breadCrumbInfo={{
          breadcrumb: data.entityUrl.breadcrumb,
          currentTitle: data.title,
          currentPath: data.entityUrl.path,
        }}
        entityTranslations={data.entityTranslations}
      />

      <Hero
        title={data.title}
        description={data.fieldDescription.value}
        breadcrumb={data.entityUrl.breadcrumb}
        linkTitle={data.fieldCallToActionLink?.title}
        linkUrl={data.fieldCallToActionLink?.url.path}
      />

      <QuickLinks
        currentLanguage={data.langcode.value}
        description={data.fieldQuickLinksDesc.value}
        popularTopics={data.fieldPopularTopics}
        relatedAgencies={data.fieldRelatedAgencies}
      />

      <FeaturedPost
        title={data.fieldFeaturedPost.entity.fieldFeaturedPostHeading}
        description={data.fieldFeaturedPost.entity.fieldFeaturedPostDescription}
        link={data.fieldFeaturedPost.entity.fieldFeaturedPostLink}
        image={featuredPostImageProps}
        caption={data.fieldFeaturedPost.entity.fieldImageCaption?.processed}
      />

      <TopicsMenu
        title={data.fieldTopicsMenuHeading}
        description={data.fieldTopicsMenuDescription.value}
        cards={data.fieldTopicMenuCard.map((card) => ({
          title: card.entity.fieldTopicMenuCardHeading,
          description: card.entity.fieldTopicMenuCardDescriptio.value,
          linkTitle: card.entity.fieldTopicMenuCardLink.title,
          linkUrl: card.entity.fieldTopicMenuCardLink.url?.path,
        }))}
      />

      {data.fieldOptionalFeaturedBanner && (
        <FeaturedBanner
          title={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerHeading}
          description={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerDescriptio}
          linkTitle={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerLink.title}
          linkUrl={data.fieldOptionalFeaturedBanner.entity.fieldFeaturedBannerLink.url?.path}
        />
      )}

      {data.fieldOptionalFeaturedPost && (
        <FeaturedPost
          optional={true}
          title={data.fieldOptionalFeaturedPost.entity.fieldOptionalFeaturedPostHea}
          description={data.fieldOptionalFeaturedPost.entity.fieldOpFeaturedPostDescripti}
          link={data.fieldOptionalFeaturedPost.entity.fieldOpFeaturedPostLink}
          image={optionalFeaturedPostImageProps}
        />
      )}
      {data.fieldAgencyResourceCard && (
        <AgencyResources
          title={data.fieldAgencyResourcesHeading?.value}
          cards={data.fieldAgencyResourceCard.map((card) => ({
            logo: card.entity.fieldAgencyLogo.entity.localFile.childImageSharp.fluid,
            alt: card.entity.fieldAgencyLogo.alt,
            name: card.entity.fieldAgencyName,
            services: card.entity.fieldAgencyResourceService,
          }))}
        />
      )}
    </Layout>
  );
}
