import React from 'react';

import './PromoVideo.scss';

interface PromoVideoProps {
  video: { input: string; videoId: string };
  videoTitle: string;
}

export default function PromoVideo(props: PromoVideoProps) {
  return (
    <div className="promo-video">
      <div className="promo-video__container">
        <h2>{props.videoTitle}</h2>

        <div className="promo-video__video-wrapper">
          <iframe
            width="100%"
            height="100%"
            className="promo-video__video-frame"
            src={props.video.input}
            title={props.videoTitle}
            aria-label={props.videoTitle}
          />
        </div>
      </div>
    </div>
  );
}
