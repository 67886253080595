import React from 'react';
import xss from 'xss';

import { Breadcrumb } from 'src/fragments/texas-node';
import Breadcrumbs from '../Generics/Breadcrumbs';
import ButtonLink from '../utils/ButtonLink';

import './Hero.scss';

interface HeroProps {
  breadcrumb: Breadcrumb[];
  title: string;
  description: string;
  linkTitle?: string;
  linkUrl?: string;
}

export default function Hero({ title, description, breadcrumb, linkTitle, linkUrl }: HeroProps) {
  return (
    <div className="wrapper">
      <div className="container container--column">
        <Breadcrumbs breadcrumb={breadcrumb} currentPageTitle={title} />
        <section className="w-2/3">
          <h1 className="hero__title">{title}</h1>
          <div
            className="hero__description pb-5"
            dangerouslySetInnerHTML={{ __html: xss(description) }}
          />
          {linkTitle && linkUrl && (
            <div>
              <ButtonLink url={linkUrl}>{linkTitle}</ButtonLink>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
