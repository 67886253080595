export enum PostType {
  Resident = 'Resident',
  Explore = 'Explore Texas',
  Business = 'Business',
  Services = 'Find Services',
  Government = 'Government',
}

export enum PostTypeSpanish {
  Resident = 'Residente',
  Explore = 'Explore Texas',
  Business = 'Empresa',
  Services = 'Buscar Servicios',
  Government = 'Gobierno',
}

export enum SocialFeed {
  Instagram = 'Instagram',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
}
