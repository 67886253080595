import React from 'react';
import Img from 'gatsby-image';

import xss from 'xss';

import classNames from 'classnames';
import Accordion from '../Generics/Accordion';
import ButtonLink from '../utils/ButtonLink';
import LinksHelper from '../utils/LinksHelper';

import { FluidObject } from '../Interfaces/Generics';

import './BodyText.scss';

interface CTA {
  uri: string;
  title: string;
}

interface BodyTextProps {
  heading: string;
  sectionId: string;
  bodyText: string;
  links?: { url: { path: string }; title: string }[];
  ctaPrimary?: CTA;
  ctaSecondary?: CTA;
  image?: { uri?: FluidObject; url?: string; alt: string };
  video?: { input: string; videoId: string };
  videoText?: string;
  videoTitle?: string;
  accordion?: {
    content: string;
    label: string;
  }[];
}

export default function BodyText(props: BodyTextProps) {
  const htmlBodyTextContent = props.bodyText;
  const sanitizedBodyTextContent = xss(htmlBodyTextContent);

  return (
    <div
      className={classNames('body_text', {
        'no-border': props.accordion && props.accordion.length > 0,
      })}
    >
      <section id={props.sectionId}>
        <h2>{props.heading}</h2>
        <div
          className="body_text__paragraph"
          dangerouslySetInnerHTML={{
            __html: sanitizedBodyTextContent,
          }}
        />
        {props.links && props.links.length > 0 && (
          <ul className="body_text__link-list">
            {props.links.map((link, i) => (
              <li key={i}>
                <LinksHelper
                  linkTitle={link.title}
                  linkUrl={link.url.path}
                  linkClassname="body_text__link"
                />
              </li>
            ))}
          </ul>
        )}
        {props.image && props.image.uri && (
          <Img className="body_text__img" fluid={props.image.uri} alt={props.image.alt} />
        )}
        {props.image && props.image.url && (
          <img className="body_text__img" src={props.image.url} alt={props.image.alt} />
        )}

        {props.videoText && (
          <div className="body_text__video-wrapper-text">
            <p>{props.videoText}</p>
          </div>
        )}

        {props.video && (
          <div className="body_text__video-wrapper">
            <iframe
              width="640px"
              height="360px"
              src={props.video.input}
              title={props.videoTitle}
              aria-label={props.videoTitle}
            />
          </div>
        )}

        {props.accordion &&
          props.accordion.map((item, i) => (
            <Accordion key={i} id={i} title={item.label} isOpen={false}>
              <div dangerouslySetInnerHTML={{ __html: xss(item.content) }}></div>
            </Accordion>
          ))}

        {(props.ctaPrimary || props.ctaSecondary) && (
          <div className="body_text__button-wrapper">
            {props.ctaPrimary && (
              <ButtonLink url={props.ctaPrimary.uri}>{props.ctaPrimary.title}</ButtonLink>
            )}
            {props.ctaSecondary && (
              <ButtonLink url={props.ctaSecondary.uri}>{props.ctaSecondary.title}</ButtonLink>
            )}
          </div>
        )}
      </section>
    </div>
  );
}
