import { PageProps, graphql } from 'gatsby';
import React from 'react';

import { TexasNode } from '../fragments/texas-node';
import Hero from '../components/Directory/Hero';
import Layout from '../components/Layout';
import PropertyTaxDirectory from '../components/Directory/PropertyTaxDirectory';
import SEO from '../components/SEO';
import { cleanPaginatedMetatags } from '../components/Directory/util';

export interface County {
  entityTranslation: {
    entityLabel: string;
    fieldCountyLink: {
      url: {
        path: string;
      };
      title: string;
    };
  };
}

export interface PropertyTaxPage extends TexasNode {
  body: {
    value: string;
  };
  fieldCallToActionLink: {
    uri: string;
    title: string;
    url: {
      path: string;
    };
  };
}

export interface PropertyTaxData {
  drupal: {
    page: PropertyTaxPage;
    counties: {
      entities: County[];
    };
  };
}

export const query = graphql`
  query($id: String!, $offset: Int!, $limit: Int!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodePropertyTaxDirectory {
          body {
            value
          }
          fieldCallToActionLink {
            title
            uri
            url {
              path
            }
          }
        }
      }

      counties: taxonomyTermQuery(
        limit: $limit
        offset: $offset
        filter: { conditions: [{ field: "vid", value: "counties", operator: EQUAL }] }
        sort: { field: "name", direction: ASC, language: EN }
      ) {
        entities {
          entityTranslation(language: EN) {
            entityLabel
            ... on Drupal_TaxonomyTermCounties {
              fieldCountyLink {
                url {
                  path
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;

interface PropertyTaxCtx {
  id: string;
  offset: number;
  pageNumber: number;
  limit: number;
  language: string;
  totalItems: number;
}

export default function PropertyTaxCounties({
  data: {
    drupal: { page, counties },
  },
  pageContext,
}: PageProps<PropertyTaxData, PropertyTaxCtx>) {
  const metaTags = cleanPaginatedMetatags(page.entityMetatags, pageContext.pageNumber);
  return (
    <Layout currentLanguage={page.langcode.value} translations={page.entityTranslations}>
      <SEO
        metatags={metaTags}
        language={page.langcode.value}
        entityTranslations={page.entityTranslations}
      />

      <Hero
        title={page.title}
        description={page.body.value}
        language={page.langcode.value}
        link={page.fieldCallToActionLink}
      />

      <PropertyTaxDirectory
        cards={counties.entities}
        pageContext={{
          ...pageContext,
          path: page.entityUrl.path,
        }}
        currentLanguage={'en'}
      />
    </Layout>
  );
}
