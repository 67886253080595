import React, { useState } from 'react';
import './TextAreaInput.scss';

interface TextAreaInputProps {
  name: string;
  label: string;
  errorMessage?: any;
  hintMessage?: string;
  className?: string;
  hideLabel?: boolean;
  required?: boolean;
  rows?: string;
  cols?: string;
  onChange: any;
  onFocus?: any;
  onBlur?: any;
  onKeyUp?: any;
  value: string;
  optionalText?: string;
  placeholder?: string;
  maxLength?: string;
}

export const TxTextAreaInput = (props: TextAreaInputProps) => {
  const {
    label,
    errorMessage,
    hintMessage,
    className = '',
    hideLabel = false,
    required,
    rows,
    cols,
    onFocus,
    onBlur,
    onKeyUp,
    optionalText = 'Optional',
    ...inputProps
  } = props;

  const [showHint, setShowHint] = useState(false);
  const extraClass = className ? ` ${className}` : '';

  function onFocusInternal(e: React.FocusEvent) {
    if (hintMessage) {
      setShowHint(true);
    }
    if (onFocus) {
      onFocus(e);
    }
  }

  function onBlurInternal(e: React.FocusEvent) {
    setShowHint(false);
    if (onBlur) {
      onBlur(e);
    }
  }
  const inputClass = determineInputClass(errorMessage, showHint);

  return React.createElement(
    'div',
    { className: 'tx-textarea__container' + extraClass },
    React.createElement(
      'label',
      { className: 'tx-textarea__label' },
      React.createElement(
        'span',
        { className: 'tx-textarea__label__text' + (hideLabel ? ' sr-only' : '') },
        label,
        !required &&
          React.createElement(
            'span',
            { className: 'tx-textarea__label__text__optional' },
            optionalText
          )
      ),

      React.createElement('textarea', {
        className: inputClass,
        required: required,
        rows,
        cols,
        onFocus: onFocusInternal,
        onBlur: onBlurInternal,
        ...inputProps,
      })
    ),

    errorMessage &&
      React.createElement(
        'div',
        { className: 'tx-textarea__error-message', 'data-testid': 'error-msg' },
        errorMessage
      ),

    hintMessage &&
      !errorMessage &&
      showHint &&
      React.createElement(
        'div',
        { className: 'tx-textarea__hint-message', 'data-testid': 'hint-msg' },
        hintMessage
      )
  );
};

function determineInputClass(errorMessage: string, showHint: boolean) {
  let classBase = 'tx-textarea__input';

  if (errorMessage) {
    classBase += ' tx-textarea__input--error';
  } else if (showHint) {
    classBase += ' tx-textarea__input--hint';
  }
  return classBase;
}
