import React from 'react';

interface IconProps {
  className?: string;
}

export default function MinusIcon(props: IconProps) {
  return (
    <svg
      {...props}
      aria-labelledby="minus-icon"
      role="img"
      viewBox="0 0 20 6"
      width="20"
      height="20"
      preserveAspectRatio="xMidYMid meet"
      className={props.className}
      x="0"
      y="0"
    >
      <title id="minus-icon">Minus Icon</title>
      <path
        d="M15,0 C15.5522847,0 16,0.44771525 16,1 C16,1.55228475 15.5522847,2 15,2 L1,2 C0.44771525,2 0,1.55228475 0,1 C0,0.44771525 0.44771525,0 1,0 L15,0 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
