import React from 'react';

import './LoadingSpinner.scss';

export function LoadingSpinnerInline() {
  return (
    <div className="loader__inline-container">
      <div className="loader loader__inline"></div>
      <p className="loaderOverlay__text--alt">Loading results...</p>
    </div>
  );
}

export default function LoadingSpinner() {
  return (
    <div className="loaderOverlay">
      <div className="loaderOverlay__inner">
        <div className="loaderOverlay__content">
          <div className="loader"></div>
          <p className="loaderOverlay__text">Loading...</p>
        </div>
      </div>
    </div>
  );
}
