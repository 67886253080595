import React from 'react';
import SEO from '../../components/SEO';
import { defaultBreadcrumbSpanish, TranslatedEntity } from '../../fragments/texas-node';
import Layout from '../../components/Layout';
import Search from '../../components/Search/Search';

export default function SearchPage() {
  const entityTranslations: TranslatedEntity[] = [
    {
      entityLanguage: { id: 'en', name: 'English' },
      entityUrl: {
        path: '/search',
      },
      entityId: '',
    },
    {
      entityLanguage: { id: 'es', name: 'Spanish' },
      entityUrl: {
        path: '/es/search',
      },
      entityId: '',
    },
  ];
  const currentPath = '/es/search';

  return (
    <Layout currentLanguage="es" translations={entityTranslations}>
      <SEO
        metatags={[
          {
            key: 'title',
            value: 'Resultados de Busqueda',
          },
        ]}
        language="es"
        breadCrumbInfo={{
          breadcrumb: defaultBreadcrumbSpanish,
          currentPath: currentPath,
          currentTitle: 'Resultados de Busqueda',
        }}
        entityTranslations={entityTranslations}
      >
        <meta name="robots" content="noindex"></meta>
      </SEO>
      <Search currentLanguage="es" currentPath={currentPath} />
    </Layout>
  );
}
