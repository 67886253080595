import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import CheckMarkIcon from '../components/Icons/CheckMarkIcon';
import GlobeIcon from '../components/Icons/GlobeIcon';

import './LanguageSwitcher.scss';

function languageLabel(language: string) {
  if (language.toLowerCase() === 'spanish') {
    return 'Español';
  } else {
    return 'English';
  }
}

function languageToggleLabel(currentLanguage: string) {
  if (currentLanguage.toLowerCase() === 'es') {
    return 'Español';
  } else {
    return 'English';
  }
}

export interface LanguageSwitcherProps {
  currentLanguage: string;
  translations?: {
    entityLanguage: {
      id: string;
      name: string;
    };
    entityUrl: {
      path: string;
    };
  }[];
}

//LanguageSwitcher has been changed to function as a toggle
//When ES is display, data for EN is linked and vice versa
export default function LanguageSwitcher({ currentLanguage, translations }: LanguageSwitcherProps) {
  return (
    <div className="language-switcher">
      {translations &&
        translations
          .filter((link) => link.entityLanguage.id != currentLanguage)
          .map((link) => (
            <Link
              key={link.entityLanguage.id}
              to={link.entityUrl.path}
              className={classNames('language-switcher__link language-switcher__desktop-link')}
            >
              <GlobeIcon className="desktop-icon" />
              <span>{languageToggleLabel(currentLanguage)}</span>
            </Link>
          ))}
    </div>
  );
}

export function LanguageSwitcherMobile({ currentLanguage, translations }: LanguageSwitcherProps) {
  return (
    <div className="language-switcher__mobile">
      <section>
        <h4 className="header">Language</h4>
      </section>
      <section className="link-container">
        {translations &&
          translations.map((link, index) => (
            <div className="mobile-link-row" key={index}>
              <Link
                key={link.entityLanguage.id}
                to={link.entityUrl.path}
                className={classNames('language-switcher__link language-switcher-mobile-link ', {
                  'language-switcher-mobile-link--active ':
                    currentLanguage === link.entityLanguage.id,
                })}
              >
                {languageLabel(link.entityLanguage.name)}
              </Link>
              {currentLanguage === link.entityLanguage.id && <CheckMarkIcon className="icon" />}
            </div>
          ))}
      </section>
    </div>
  );
}
