import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { search, SearchResponse, SearchResult } from '../../search';
import { useLocation } from '@reach/router';

import { LunrQueryOpts } from '../../lunr-query';

import './CountyCard.scss';
import './PropertyTaxDirectory.scss';

import Pagination from '../Generics/Pagination';
import SearchBox from '../Generics/SearchBox';
import { translateLanguage, displaySearchMessage } from '../utils/UtilityFunctions';
import { County } from '../../templates/PropertyTaxCounties';
import CountyCard from './CountyCard';
interface PropertyTaxCtx {
  language: string;
  pageNumber: number;
  limit: number;
  totalItems: number;
  path: string;
}

interface PropertyTaxDirectoryProps {
  cards: County[];
  pageContext: PropertyTaxCtx;
  currentLanguage: string;
}

export default function PropertyTaxDirectory({
  cards,
  pageContext,
  currentLanguage,
}: PropertyTaxDirectoryProps) {
  const [filteredCards, setFilteredCards] = useState<any[]>(cards);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [totalResults, setTotalResults] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [quickSearchResults, setQuickSearchResults] = useState<SearchResult[]>([]);
  const [searchParams, setSearchParams] = useState<string>('');

  const language = pageContext.language.toLowerCase();

  const resultsStartRef = useRef() as MutableRefObject<HTMLDivElement>;

  const searchQuery: LunrQueryOpts[] = [
    { field: 'nodeType', query: 'Drupal_TaxonomyTermCounties', required: true },
  ];

  if (searchTerm) {
    searchQuery.push({ query: searchTerm, required: true, field: 'title' });
  }

  function runSearch(currentPage?: number): Promise<SearchResponse> {
    return search(currentLanguage, searchQuery, {
      pageSize: pageContext.limit,
      currentPage: currentPage || pageNumber,
    });
  }

  useEffect(() => {
    if (!searchTerm) {
      setFilteredCards(cards);
    } else {
      runSearch(1).then((res) => {
        setTotalResults(res.totalResults);
        setFilteredCards(res.results);
        setPageNumber(1);
      });
    }
  }, [searchTerm]);

  const searchTermSubmit = (term: string) => {
    setSearchTerm(term);
    setQuickSearchResults([]);
    searchQuery.push({ query: searchTerm, required: true, field: 'title' });

    const url = new URL(location.href);

    url.searchParams.set('search', term);

    history.pushState({}, 'test', url.toString());

    setSearchParams(term);
  };

  const usePagination = (n: number) => {
    runSearch(n)?.then((res) => {
      setPageNumber(n);
      setFilteredCards(res.results);
      setTotalResults(res.totalResults);
      if (resultsStartRef && resultsStartRef.current) {
        resultsStartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  };

  function onChange(value: string) {
    if (value.length >= 1) {
      if (searchQuery.length <= 1) {
        searchQuery.push({ query: value + '*', required: true, field: 'title' });
      } else {
        searchQuery[1] = { query: value + '*', required: true, field: 'title' };
      }
      runSearch(1).then((res) => {
        setQuickSearchResults(res.results);
      });
    } else {
      setQuickSearchResults([]);
    }
  }

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const searchParam = queryParams.get('search') || '';

    searchTermSubmit(searchParam);
  }, [location]);

  return (
    <section
      className="wrapper wrapper--grey-7 services-wrapper"
      id="results-start"
      ref={resultsStartRef}
    >
      <div className="container">
        <div className="property-tax-dir__content w-2/3">
          <div className="property-tax-dir__input-section">
            <SearchBox
              pageContextLanguage={pageContext.language}
              placeholder={translateLanguage(language, 'Search by county name', 'Buscar ')}
              onSearch={searchTermSubmit}
              onChangeSearch={onChange}
              language={language}
              quickSearchResults={quickSearchResults}
              initialValue={searchParams}
            />

            <hr className="property-tax-dir__divider" />
          </div>

          {searchTerm && (
            <div className="property-tax-dir__filter-results">
              <div className="property-tax-dir__display-message">
                {searchTerm && <h3>{displaySearchMessage(totalResults, searchTerm, language)}</h3>}
              </div>
            </div>
          )}

          {filteredCards.map((card, i) => {
            let cardTranslation = card;
            if (card.entityTranslation) {
              cardTranslation = card.entityTranslation;
            }

            return (
              <CountyCard
                key={i}
                title={cardTranslation.entityLabel}
                path={cardTranslation.fieldCountyLink?.url.path}
                language={language}
              />
            );
          })}
          {!searchTerm ? (
            <Pagination
              currentLanguage={language}
              totalItems={pageContext.totalItems}
              pageSize={pageContext.limit}
              currentPage={pageContext.pageNumber}
              createLinkUrl={(n) =>
                n === 1
                  ? `${pageContext.path}#results-start`
                  : `${pageContext.path}${n}/#results-start`
              }
            />
          ) : (
            <Pagination
              currentLanguage={language}
              totalItems={totalResults}
              pageSize={pageContext.limit}
              currentPage={pageNumber}
              createLinkUrl={() => ''}
              onClick={usePagination}
            />
          )}
        </div>
      </div>
    </section>
  );
}
