import React from 'react';

//Utilities
import { TxAlert, TxAlertType } from 'texkit-ui/components';

import '../Form.scss';
import './EmailError.scss';
import { formText } from './FormText';

interface EmailErrorProps {
  currentLanguage: string;
  type: TxAlertType;
}

export default function EmailError({ currentLanguage, type }: EmailErrorProps) {
  const errorHeaderText = (type: string, currentLanguage: string) => {
    if (type === 'warning' && currentLanguage === 'en') {
      return formText.en_email_error_yellow_header;
    } else if (type === 'warning' && currentLanguage === 'es') {
      return formText.es_email_error_yellow_header;
    } else if (type === 'error' && currentLanguage === 'en') {
      return formText.en_email_error_red_header;
    } else {
      return formText.es_email_error_red_header;
    }
  };

  const errorDescriptionText = (type: string, currentLanguage: string) => {
    if (type === 'warning' && currentLanguage === 'en') {
      return formText.en_email_error_yellow_message;
    } else if (type === 'warning' && currentLanguage === 'es') {
      return formText.es_email_error_yellow_message;
    } else if (type === 'error' && currentLanguage === 'en') {
      return formText.en_email_error_red_message;
    } else {
      return formText.es_email_error_red_message;
    }
  };

  return (
    <div className="form-error">
      <TxAlert heading={errorHeaderText(type, currentLanguage)} type={type}>
        {errorDescriptionText(type, currentLanguage)}
      </TxAlert>
    </div>
  );
}
