import React from 'react';
import xss from 'xss';

import classNames from 'classnames';

//Components
import { TxInput, TxButton, TxAlertType } from 'texkit-ui/components';
import { TxFormGroup, TxFormActions } from 'texkit-ui/forms';

//Utilities
import { FormikProps } from 'formik';
import { transformToId } from '../../utils/UtilityFunctions';
import {
  formText,
  dropdownListItemsEnglish,
  dropdownListItemsSpanish,
} from './FormComponents/FormText';
import { FormFields } from './FormComponents/FormSchemas';

//Generic Component
import { TxTextAreaInput } from './FormComponents/TextAreaInput';
import EmailError from './FormComponents/EmailError';
import { TxInputMask } from './FormComponents/InputMask';

//Styles
import './Form.scss';
import CustomSelect, { OptionsType } from '../Form/FormComponents/CustomSelect';

//Typescript Interfaces
interface FormProps {
  currentLanguage: string;
  formInformation: FieldForm[];
  formik: FormikProps<FormFields>;
  emailError: boolean;
  type?: TxAlertType;
}

interface FieldForm {
  header: string;
  description: string;
}

export default function Form({
  formInformation,
  currentLanguage,
  formik,
  emailError,
  type,
}: FormProps) {
  const hasChanged = (initialValue: string, value: string) =>
    initialValue === value ? false : true;

  const characterCount = (value: string, max: number, currentLanguage: string) => {
    if (value.length > max) {
      return (
        <>
          {`${value.length - max}`} {currentLanguage === 'en' ? 'too many' : 'demasiados'}
        </>
      );
    } else if (value.length <= max && value.length !== 0) {
      return (
        <>
          {`${max - value.length}`}{' '}
          {currentLanguage === 'en' ? 'characters left' : 'caracteres restantes'}
        </>
      );
    } else {
      return (
        <>
          {`${max}`} {currentLanguage === 'en' ? 'characters allowed' : 'caracteres permitidos'}
        </>
      );
    }
  };

  const checkValueChange =
    (hasChanged(formik.initialValues.contactCode, formik.values.contactCode) &&
      formik.errors.contactCode) ||
    (formik.touched.contactCode && formik.errors.contactCode);
  return (
    <div className="form">
      <form onSubmit={formik.handleSubmit} noValidate>
        <section id={transformToId(formInformation[0].header)}>
          <h2 className="form__label form__label--padding">{formInformation[0].header}</h2>
          <div
            className="form__description"
            dangerouslySetInnerHTML={{ __html: xss(formInformation[0].description) }}
          />
          <TxFormGroup>
            <TxInput
              className="pb-5"
              label={currentLanguage === 'en' ? formText.en_name_label : formText.es_name_label}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                (hasChanged(formik.initialValues.name, formik.values.name) && formik.errors.name) ||
                (formik.touched.name && formik.errors.name)
                  ? formik.errors.name
                  : null
              }
              required={true}
            />

            <TxInput
              className="pb-5"
              label={currentLanguage === 'en' ? formText.en_email_label : formText.es_email_label}
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.touched.email && formik.errors.email ? formik.errors.email : null
              }
              required={true}
            />

            <TxInputMask
              mask="999-999-9999"
              value={formik.values.phone}
              onChange={formik.handleChange}
            >
              <TxInput
                className="pb-5"
                placeholder="000-000-0000"
                autoComplete="000-000-0000"
                name="phone"
                onBlur={formik.handleBlur}
                label={currentLanguage === 'en' ? formText.en_phone_label : formText.es_phone_label}
                errorMessage={
                  formik.touched.phone && formik.errors.phone ? formik.errors.phone : null
                }
                optionalText={
                  currentLanguage === 'en' ? formText.en_optional : formText.es_optional
                }
              />
            </TxInputMask>
          </TxFormGroup>
        </section>

        <section id={transformToId(formInformation[1].header)}>
          <h2 className="form__label form__label--padding">{formInformation[1].header}</h2>
          <div
            className="form__description"
            dangerouslySetInnerHTML={{ __html: xss(formInformation[1].description) }}
          />

          <TxFormGroup>
            <div className="tx-textarea__container pb-5 ">
              <label className="tx-textarea__label">
                <span className="tx-textarea__label__text">
                  {currentLanguage === 'en' ? formText.en_reason_label : formText.es_reason_label}
                </span>
              </label>
              <CustomSelect
                id="contactCode"
                key={formik.values.contactCode}
                myKey={formik.values.contactCode}
                classNamePrefix="react-select"
                className="react-select"
                onChange={(value: OptionsType) =>
                  formik.setFieldValue('contactCode', value === null ? '' : value.value)
                }
                onBlur={(value: any) =>
                  formik.setFieldTouched('contactCode', value === null ? '' : value.value)
                }
                value={formik.values.contactCode}
                placeholder={
                  currentLanguage === 'en'
                    ? formText.en_reason_placeholder
                    : formText.es_reason_placeholder
                }
                options={
                  currentLanguage === 'en' ? dropdownListItemsEnglish : dropdownListItemsSpanish
                }
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles
                checkValueChange={checkValueChange}
              />
              {checkValueChange ? (
                <div className="tx-textarea__error-message">{formik.errors.contactCode}</div>
              ) : null}
            </div>

            <TxTextAreaInput
              label={
                currentLanguage === 'en' ? formText.en_service_label : formText.es_service_label
              }
              name="txgovService"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.txgovService}
              errorMessage={
                (hasChanged(formik.initialValues.txgovService, formik.values.txgovService) &&
                  formik.errors.txgovService) ||
                (formik.touched.txgovService && formik.errors.txgovService)
                  ? formik.errors.txgovService
                  : null
              }
              placeholder={
                currentLanguage === 'en'
                  ? formText.en_service_placeholder
                  : formText.es_service_placeholder
              }
              required={true}
              rows="3"
              onKeyUp={formik.handleChange}
            />
            <div className="form__character-count__container">
              <p
                className={classNames('form__character-count', {
                  'form__character-count--error': formik.values.txgovService.length > 250,
                })}
              >
                {characterCount(formik.values.txgovService, 250, currentLanguage)}
              </p>
            </div>
            <TxTextAreaInput
              label={
                currentLanguage === 'en' ? formText.en_describe_label : formText.es_describe_label
              }
              name="issue"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.issue}
              errorMessage={
                (hasChanged(formik.initialValues.issue, formik.values.issue) &&
                  formik.errors.issue) ||
                (formik.touched.issue && formik.errors.issue)
                  ? formik.errors.issue
                  : null
              }
              placeholder={
                currentLanguage === 'en'
                  ? formText.en_describe_placeholder
                  : formText.es_describe_placeholder
              }
              required={true}
              rows="6"
              onKeyUp={formik.handleChange}
            />
            <div className="form__character-count__container">
              <p
                className={classNames('form__character-count', {
                  'form__character-count--error': formik.values.issue.length > 500,
                })}
              >
                {characterCount(formik.values.issue, 500, currentLanguage)}
              </p>
            </div>
            {emailError ? <EmailError type={type!} currentLanguage={currentLanguage} /> : null}
          </TxFormGroup>
        </section>
        <TxFormActions>
          <div className="form__submit">
            {/* @ts-ignore */}
            <TxButton disabled={formik.isSubmitting} mode="tertiary" type="submit">
              {currentLanguage === 'en' ? formText.en_submit_label : formText.es_submit_label}
            </TxButton>
          </div>
        </TxFormActions>
      </form>
    </div>
  );
}
