// Transforms string into id format in lowercase and without spaces
export function transformToId(heading: string): string {
  return heading
    .trim()
    .replace(/[^A-Z0-9]+/gi, '-')
    ?.toLowerCase();
}

export const translateLanguage = (
  currentLanguage: string,
  englishTranslation: string,
  spanishTranslation: string
): string => {
  switch (currentLanguage) {
    case 'es':
      return spanishTranslation;
    case 'en':
    default:
      return englishTranslation;
  }
};

export function displaySearchMessage(count: number, searchValue: string, language: string) {
  if (count > 1 && searchValue) {
    return translateLanguage(
      language,
      `${count} results found for "${searchValue}"`,
      `${count} resultados encontrados para "${searchValue}"`
    );
  } else if (count === 1 && searchValue) {
    return translateLanguage(
      language,
      `${count} result found for "${searchValue}"`,
      `${count} resultado encontrado para "${searchValue}"`
    );
  } else if (count === 0 && searchValue) {
    return translateLanguage(
      language,
      `No results found for "${searchValue}"`,
      `No se encontraron resultados de búsqueda para "${searchValue}"`
    );
  } else {
    return '';
  }
}

export function displayFilterMessage(count: number, language: string) {
  if (count > 1) {
    return translateLanguage(
      language,
      `${count} filtered results found`,
      `${count} resultados encontrados con este filtro`
    );
  } else if (count === 1) {
    return translateLanguage(
      language,
      `${count} filtered result found`,
      `${count} resultado encontrado con este filtro`
    );
  } else if (count === 0) {
    return translateLanguage(
      language,
      'No results found.',
      'Disculpa, no se encontraron resultados.'
    );
  } else {
    return '';
  }
}
