import React, { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import xss from 'xss';

import Navigation from './Navigation';
import Footer from './Footer';
import CookiesBanner from './CookiesBanner';

import { LanguageSwitcherProps } from './LanguageSwitcher';
import AlertBanner from './Generics/AlertBanner';
import Olark from 'react-olark';

interface ChildrenProps {
  children: ReactNode;
}

type LayoutProps = ChildrenProps & LanguageSwitcherProps;

export default function Layout({ currentLanguage, translations, children }: LayoutProps) {
  const htmlContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P3H2928"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
  
`;
  const sanitizedContent = xss(htmlContent);

  useEffect(() => {
    const olarks = document.querySelectorAll('a[data-olark]');
    olarks.forEach((el) => {
      el.addEventListener('click', (e: Event) => {
        e.preventDefault();
        (window as any).olark('api.box.expand');
      });
    });
  }, []);
  return (
    <>
      <Helmet>
        {/* Changes to update the polyfill domain to use cloudflare domain for INC0423987*/}
        <script src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=Promise%2CNodeList.prototype.forEach%2CElement.prototype.closest"></script>
        {/* Google Tag Manager */}
        <script>
          {`{
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-P3H2928');
          
          }`}
        </script>
        {/* End Google Tag Manager */}
      </Helmet>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: sanitizedContent,
        }}
      />
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <AlertBanner {...{ currentLanguage }} />
      <Navigation {...{ currentLanguage, translations }} />
      <main>{children}</main>
      <CookiesBanner />

      <Footer {...{ currentLanguage }} />
      {process.env.GATSBY_TEXAS_ENV == 'production' && <Olark siteId={'4803-719-10-5079'} />}
    </>
  );
}
