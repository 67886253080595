import React from 'react';

import './OnThisPageSidebar.scss';

interface OnThisPageSidebarProps {
  currentLanguage: string;
  links: { url: string; title: string }[];
}

export default function OnThisPageSidebar(props: OnThisPageSidebarProps) {
  return (
    <div className="font-color-gray on-this-page-sidebar">
      <section>
        <h2 className="h5">{props.currentLanguage === 'en' ? 'On this page' : 'En esta pagina'}</h2>
        <ul aria-label="On This Page Headers">
          {props.links.map((item, i) => (
            <li key={i}>
              <a className="h5 font-weight-medium font-color-gray" href={item.url}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
