import React from 'react';
import Layout from '../../components/Layout';
import Hero from '../../components/Homepage/Hero';
import FeaturedPost from '../../components/Homepage/FeaturedPost';
import TopicMenuCard from '../../components/Homepage/TopicMenuCard';
import { HomeProps } from '../../fragments/homepage-node';
import SEO from '../../components/SEO';

import { TranslatedEntity } from '../../fragments/texas-node';

import './HomePageBody.scss';

//manual update for url on translations
export function updatedTranslations(entityTranslations: TranslatedEntity[]) {
  entityTranslations.forEach((translation: TranslatedEntity) => {
    if (translation.entityLanguage.id === 'en') {
      translation.entityUrl.path = '/';
    } else {
      translation.entityUrl.path = '/es/';
    }
  });
}

export default function HomePageBody(data: HomeProps) {
  let featuredPostImageProps;

  if (
    data.fieldFeaturedPost.entity.fieldFeaturedPostImage?.entity?.localFile?.childImageSharp?.fluid
  ) {
    featuredPostImageProps = {
      fluid:
        data.fieldFeaturedPost.entity.fieldFeaturedPostImage.entity.localFile.childImageSharp.fluid,
      alt: data.fieldFeaturedPost.entity.fieldFeaturedPostImage.alt,
    };
  }

  updatedTranslations(data.entityTranslations);

  return (
    <Layout currentLanguage={data.langcode.value} translations={data.entityTranslations}>
      <SEO
        metatags={data.entityMetatags}
        language={data.langcode.value}
        entityTranslations={data.entityTranslations}
      >
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Texas.gov",
          "url": "https://www.texas.gov/",
          "logo": "https://www.texas.gov/images/txgov_logo.png",
          "address": {
          "@type": "PostalAddress",
            "streetAddress": "300 W 15th St #1300",
            "addressLocality": "Austin",
            "addressRegion": "TX",
            "postalCode": "78701",
            "addressCountry": "USA"
          },
          "sameAs": [
            "http://www.facebook.com/Texas.gov",
            "https://twitter.com/texasgov",
            "http://www.youtube.com/user/TexasGov",
            "https://www.instagram.com/texasgov/"
          ]
          }`}
        </script>
        <script type="application/ld+json">
          {`{
         "@context" : "https://schema.org",
         "@type": "WebSite",
          "url": "https://www.texas.gov/",
          "potentialAction": {
        "@type": "SearchAction",
          "target": "https://www.texas.gov/search/?search={search_term_string}",
          "query-input": "required name=search_term_string"
         }
       }`}
        </script>
      </SEO>
      <Hero
        title={data.title}
        description={data.fieldDescription.value}
        subDescription={data.fieldSubDescription.value}
        background_photo={{
          fixed: data.fieldBackgroundImage.entity.localFile.childImageSharp.fixed,
          fluid: data.fieldBackgroundImage.entity.localFile.childImageSharp.fluid,
        }}
      />
      <div className="wrapper wrapper--white" data-testid="homepage-content-wrapper">
        <FeaturedPost
          title={data.fieldFeaturedPost.entity.fieldFeaturedPostHeading}
          description={data.fieldFeaturedPost.entity.fieldFeaturedPostDescription}
          postType={data.fieldFeaturedPost.entity.fieldPostType}
          link={data.fieldFeaturedPost.entity.fieldFeaturedPostLink}
          image={featuredPostImageProps}
          language={data.langcode.value}
        />
        <div className="container">
          {data.fieldTopicMenuCard.map((card, i) => (
            <TopicMenuCard
              key={i}
              title={card.entity.fieldTopicMenuCardHeading}
              description={card.entity.fieldTopicMenuCardDescriptio.value}
              postType={card.entity.fieldPostType}
              link={card.entity.fieldTopicMenuCardLink}
              language={data.langcode.value}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}
