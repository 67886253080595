import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import xss from 'xss';

import './AlertBanner.scss';

export interface AlertData {
  drupal: {
    english: TaxonomyTermQuery;
    spanish: TaxonomyTermQuery;
  };
}

export interface TaxonomyTermQuery {
  entities: Alert[];
}

interface Alert {
  entityTranslation?: {
    fieldPriority: string;
    description: {
      value: string;
    };
    fieldStartDate?: {
      date: string;
      value: string;
    };
    fieldEndDate?: {
      date: string;
      value: string;
    };
  };
}

// return up to 3 alerts within date range
function filterAlertData(data: TaxonomyTermQuery): Alert[] {
  const today = Date.now();

  return data.entities
    .filter((alert) => {
      const startDate = alert.entityTranslation?.fieldStartDate?.date;
      const endDate = alert.entityTranslation?.fieldEndDate?.date;

      const hasTranslation = !!alert.entityTranslation;
      const startDateValid = startDate ? today >= Date.parse(startDate.replace(/-/g, '/')) : true;
      const endDateValid = endDate ? today <= Date.parse(endDate.replace(/-/g, '/')) : true;

      return hasTranslation && startDateValid && endDateValid;
    })
    .slice(0, 3);
}

export default function AlertBanner({ currentLanguage }: { currentLanguage?: string }) {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const data = useStaticQuery<AlertData>(graphql`
    query allTaxonomyAlerts {
      drupal {
        english: taxonomyTermQuery(
          filter: { conditions: { field: "vid", value: "alerts" } }
          sort: { field: "field_priority", direction: ASC }
        ) {
          entities {
            ... on Drupal_TaxonomyTermAlerts {
              entityTranslation(language: EN) {
                ... on Drupal_TaxonomyTermAlerts {
                  description {
                    value
                  }
                  fieldPriority
                  fieldStartDate {
                    date
                    value
                  }
                  fieldEndDate {
                    value
                    date
                  }
                  vid {
                    targetId
                  }
                }
              }
            }
          }
        }
        spanish: taxonomyTermQuery(
          filter: { conditions: { field: "vid", value: "alerts" } }
          sort: { field: "field_priority", direction: ASC }
        ) {
          entities {
            ... on Drupal_TaxonomyTermAlerts {
              entityTranslation(language: ES) {
                ... on Drupal_TaxonomyTermAlerts {
                  description {
                    value
                  }
                  fieldPriority
                  fieldStartDate {
                    date
                    value
                  }
                  fieldEndDate {
                    value
                    date
                  }
                  vid {
                    targetId
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    switch (currentLanguage?.toUpperCase()) {
      case 'ES':
        setAlerts(filterAlertData(data.drupal.spanish));
        break;

      case 'EN':
        setAlerts(filterAlertData(data.drupal.english));
        break;

      default:
        setAlerts(filterAlertData(data.drupal.english));
    }
  }, []);

  return (
    <>
      {alerts?.map((alert, i) => {
        return (
          <div
            key={i}
            data-testid="alert"
            className={`alert-banner alert-banner__type-${alert.entityTranslation?.fieldPriority}`}
            dangerouslySetInnerHTML={{
              __html: xss(alert.entityTranslation?.description.value || ''),
            }}
          />
        );
      })}
    </>
  );
}
