import React from 'react';

interface IconProps {
  className?: string;
}

export default function HomeGovernmentIcon(props: IconProps) {
  return (
    <svg {...props} role="img" viewBox="0 0 25 24" className={props.className}>
      <title>Government Icon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-727.000000, -1717.000000)" fill="#B67C07" fillRule="nonzero">
          <g id="Home-Page-Icons" transform="translate(64.000000, 1561.000000)">
            <g id="home-government" transform="translate(663.000000, 156.000000)">
              <path
                d="M5.08697971,23.3242899 C5.53266087,23.6612174 6.09785507,23.541658 6.77175652,23.0525681 L12.5217855,18.8351536 L18.2826667,23.0525681 C18.9565681,23.541658 19.5109101,23.6612174 19.9674435,23.3242899 C20.4130783,22.9873159 20.5109333,22.4329739 20.2391652,21.639513 L17.9673971,14.8786087 L23.7717333,10.7046957 C24.4457739,10.226458 24.7173565,9.72646957 24.5434899,9.18297971 C24.3696232,8.66124058 23.8587362,8.40037101 23.0217275,8.41122319 L15.9021913,8.45472464 L13.7391768,1.66121739 C13.4783072,0.856857971 13.0869797,0.454724638 12.5217855,0.454724638 C11.9674435,0.454724638 11.5761159,0.856857971 11.3152464,1.66121739 L9.15218551,8.45472464 L2.03264928,8.41122319 C1.19568696,8.40037101 0.6848,8.66124058 0.510886957,9.18297971 C0.326121739,9.72646957 0.608742029,10.226458 1.28264348,10.7046957 L7.08697971,14.8786087 L4.81525797,21.639513 C4.54348986,22.4329739 4.64134493,22.9873159 5.08697971,23.3242899 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
