import React, { useState } from 'react';

import SearchIcon from '../Icons/SearchIcon';

import './NavSearch.scss';
import { search, SearchResult } from '../../search';
import NavQuickSearchResults from './NavQuickSearchResults';
import { LoadingSpinnerInline } from '../Generics/LoadingSpinner';

export interface NavSearchProps {
  language: string;
}

export default function NavSearch({ language }: NavSearchProps) {
  const [quickSearchResults, setQuickSearchResults] = useState<SearchResult[]>([]);
  const [loading, isLoading] = useState<boolean>(false);

  function onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = e.target.value;

    if (value.length >= 2) {
      isLoading(true);
      search(language, [{ query: e.target.value, fuzzy: 1, required: true }], {
        pageSize: 10,
      }).then((res) => {
        isLoading(false);
        setQuickSearchResults(res.results);
      });
    } else {
      isLoading(false);
      setQuickSearchResults([]);
    }
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key.toUpperCase() === 'ENTER') {
      e.preventDefault();

      const target = e.target as HTMLElement;

      target.closest('form')?.submit();
    }
  }

  return (
    <div className="nav-search">
      <form className="nav-search__form" action={language === 'en' ? '/search/' : '/es/search/'}>
        <textarea
          name="search"
          className="nav-search__input"
          placeholder={
            language === 'en'
              ? 'What can we help you find today?'
              : 'Que te podemos ayudar a encontrar hoy?'
          }
          onChange={onChange}
          onKeyDown={onKeyDown}
          ref={(input) => input?.focus()}
          aria-label="Search"
        />

        <button type="submit" aria-label="Submit">
          <SearchIcon className="nav-search__icon" />
        </button>
      </form>

      {loading && <LoadingSpinnerInline />}

      {quickSearchResults.length > 0 && <NavQuickSearchResults results={quickSearchResults} />}
    </div>
  );
}
