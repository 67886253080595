import React from 'react';
import { Link } from 'gatsby';
import ExternalLinkIcon from '../Icons/ExternalLinkIcon';

import './LinksHelper.scss';

interface LinksHelperProps {
  linkTitle: string;
  linkUrl: string;
  linkClassname?: string;
  linkExternalIcon?: boolean;
}

export default function LinksHelper({
  linkTitle,
  linkUrl,
  linkClassname = '',
  linkExternalIcon = true,
}: LinksHelperProps) {
  if (linkUrl.startsWith('/')) {
    return (
      <Link to={linkUrl} className={linkClassname}>
        <span>{linkTitle}</span>
      </Link>
    );
  } else {
    return (
      <>
        <a href={linkUrl} className={`links-helper__external ${linkClassname}`} target="__blank">
          {linkExternalIcon && <ExternalLinkIcon className="links-helper__icon" />}
          <span>{linkTitle}</span>
        </a>
      </>
    );
  }
}
