import React from 'react';

interface IconProps {
  className?: string;
}

export default function HomeResidentIcon(props: IconProps) {
  return (
    <svg {...props} role="img" viewBox="0 0 26 26" className={props.className}>
      <title>Resident Icon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1226.000000, -1715.000000)"
          fill="#8158AB"
          fillRule="nonzero"
          stroke="#8158AB"
          strokeWidth="1.21435934"
        >
          <g id="Home-Page-Icons" transform="translate(64.000000, 1561.000000)">
            <g id="Resident-Icon" transform="translate(1163.000000, 155.000000)">
              <path
                d="M7.2165796,0 L7.2165796,9.88669695 L0,9.88669695 C0.595983289,11.3465023 1.29645735,12.192707 2.10142218,12.425311 C3.30886943,12.774217 3.84548254,13.7908786 4.41418641,15.0853703 C4.98289028,16.3798619 5.49035289,16.7994574 6.21503809,16.7994574 C6.51139323,15.8517795 7.18780558,15.0853703 8.32857149,15.0853703 C9.4693374,15.0853703 10.2437362,15.423923 11.0001357,16.995338 C11.7565352,18.5667531 12.0569589,18.6663731 12.7000543,19.3288665 C13.3431497,19.9913598 13.4017105,20.5417394 13.8382526,22.069504 C14.2747948,23.5972686 15.7539595,24 17.6080259,24 C17.6080259,21.0159703 17.490159,19.2148388 18.8225811,18.2610181 C20.1550031,17.3071973 22.8667561,16.6122913 23.1566422,14.6452911 C23.4465283,12.6782909 24.5161667,11.7806628 23.6970163,11.0515455 C22.8778658,10.3224282 23.3342014,8.40451389 23.1566422,7.63195927 C22.4458941,6.55370923 22.1614255,6.55370923 16.2937462,6.55370923 C13.7449469,6.55370923 12.7000543,4.68932802 12.7000543,3.10784582 C12.7000543,2.05352435 12.7000543,1.01757574 12.7000543,0 L7.2165796,0 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
