import React from 'react';
import HomepageIcons from './HomepageIcons';
import { PostType } from '../utils/enums';
import { Link, FluidObject } from '../../components/Interfaces/Generics';
import LinksHelper from '../utils/LinksHelper';
import './FeaturedPost.scss';

interface FeaturedPostProps {
  title: string;
  description: string;
  postType?: PostType;
  link: Link;
  image?: { fluid: FluidObject; alt: string };
  language: string;
}

export default function FeaturedPost(props: FeaturedPostProps) {
  return (
    <div className="post-home container">
      <div className="w-1/2 post-home__text">
        {props.postType && <HomepageIcons postType={props.postType} language={props.language} />}
        <h2 className="post-home__title">{props.title}</h2>
        <p className="post-home__description">{props.description}</p>
        <LinksHelper linkUrl={props.link.url.path} linkTitle={props.link.title} />
      </div>
      {props.image?.fluid.src && (
        <div className="w-1/2 post-home__photo">
          <div className="post-home__photo-container">
            <img className="post-home__image" alt={props.image.alt} src={props.image.fluid.src} />
          </div>
        </div>
      )}
    </div>
  );
}
