import React from 'react';

import './CountyCard.scss';
import '../utils/LinksHelper.scss';

import LinksHelper from '../utils/LinksHelper';
import ExternalLinkIcon from '../Icons/ExternalLinkIcon';

import { translateLanguage } from '../utils/UtilityFunctions';

interface CountyCardProps {
  title: string;
  path: string;
  language: string;
}

export default function CountyCard({ title, path, language }: CountyCardProps) {
  return (
    <div className="county-card__card-design">
      <div className="container">
        <div className="county-card__align-title-link">
          {path ? (
            <LinksHelper linkTitle={title} linkUrl={path} linkClassname="county-card__link" />
          ) : (
            <div className="links-helper__external county-card__link county-card__link-inactive">
              <ExternalLinkIcon className="links-helper__icon" />
              <span>
                {title}
                {translateLanguage(language, ' (coming soon)', ' (próximamente)')}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
