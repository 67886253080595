import React from 'react';

interface IconProps {
  className?: string;
}

export default function HamburgerIcon(props: IconProps) {
  return (
    <svg
      {...props}
      aria-labelledby="hamburger-icon"
      role="img"
      viewBox="0 0 24 24"
      className={props.className}
    >
      <title id="hamburger-icon">Hamburger Icon</title>
      <path d="M21,17 C21.5522847,17 22,17.4477153 22,18 C22,18.5522847 21.5522847,19 21,19 L21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 C2,17.4477153 2.44771525,17 3,17 L3,17 Z M21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L21,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L3,11 Z M21,5 C21.5522847,5 22,5.44771525 22,6 C22,6.55228475 21.5522847,7 21,7 L21,7 L3,7 C2.44771525,7 2,6.55228475 2,6 C2,5.44771525 2.44771525,5 3,5 L3,5 Z"></path>
    </svg>
  );
}
