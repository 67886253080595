import React from 'react';
import HomeBusinessIcon from '../Icons/HomeBusinessIcon';
import HomeExploreIcon from '../Icons/HomeExplorerIcon';
import HomeFindServicesIcon from '../Icons/HomeFindServicesIcon';
import HomeGovernmentIcon from '../Icons/HomeGovernmentIcon';
import HomeResidentIcon from '../Icons/HomeResidentIcon';
import { PostType, PostTypeSpanish } from '../utils/enums';
import { translateLanguage } from '../utils/UtilityFunctions';

import './HomepageIcons.scss';

interface HomepageIcons {
  postType: PostType;
  language: string;
}

export default function HomepageIcons(props: HomepageIcons) {
  let HomePageIcon = null;
  let iconClass;
  let iconText;
  switch (props.postType) {
    case PostType.Resident:
      HomePageIcon = <HomeResidentIcon className="icon" />;
      iconClass = 'resident';
      iconText = translateLanguage(props.language, PostType.Resident, PostTypeSpanish.Resident);
      break;
    case PostType.Business:
      HomePageIcon = <HomeBusinessIcon className="icon" />;
      iconClass = 'business';
      iconText = translateLanguage(props.language, PostType.Business, PostTypeSpanish.Business);
      break;
    case PostType.Explore:
      HomePageIcon = <HomeExploreIcon className="icon" />;
      iconClass = 'explore';
      iconText = translateLanguage(props.language, PostType.Explore, PostTypeSpanish.Explore);
      break;
    case PostType.Government:
      HomePageIcon = <HomeGovernmentIcon className="icon" />;
      iconClass = 'government';
      iconText = translateLanguage(props.language, PostType.Government, PostTypeSpanish.Government);
      break;
    case PostType.Services:
      HomePageIcon = <HomeFindServicesIcon className="icon" />;
      iconClass = 'services';
      iconText = translateLanguage(props.language, PostType.Services, PostTypeSpanish.Services);
      break;
  }
  return (
    <>
      {HomePageIcon}
      <p className={'post-type ' + iconClass}>{iconText}</p>
    </>
  );
}
