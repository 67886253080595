import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import TexasIcon from './Icons/TexasIcon';
import HamburgerIcon from './Icons/HamburgerIcon';
import NavigationMenuMobile from './NavigationMenuMobile';
import LanguageSwitcher from './LanguageSwitcher';
import { LanguageSwitcherProps } from './LanguageSwitcher';
import { translateLanguage } from './utils/UtilityFunctions';

import NavSearch from './NavSearch/NavSearch';
import SearchIcon from './Icons/SearchIcon';
import CloseIcon from './Icons/CloseIcon';

import './Navigation.scss';

export interface Url {
  translate: {
    path: string;
  };
}

export interface DrupalLink {
  label: string;
  links: DrupalLink[];
  url: Url;
}

interface Menu {
  name: string;
  links: DrupalLink[];
}

interface DrupalMenuItems {
  drupal: {
    english: Menu;
    spanish: Menu;
  };
}

export default function Navigation({ currentLanguage, translations }: LanguageSwitcherProps) {
  const [selectedActive, setSelectedActive] = useState('');
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [hamburgerMenuToggle, setHamburgerMenuToggle] = useState(false);
  const [searchOpen, isSearchOpen] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState<DrupalLink | undefined>();

  const navRef = useRef<HTMLDivElement>(null);

  const { drupal } = useStaticQuery<DrupalMenuItems>(graphql`
    query {
      drupal {
        english: menuByName(name: "main") {
          name
          links {
            label
            url {
              translate(language: EN) {
                path
              }
            }
            links {
              label
              url {
                translate(language: EN) {
                  path
                }
              }
              links {
                label
                url {
                  translate(language: EN) {
                    path
                  }
                }
              }
            }
          }
        }
        spanish: menuByName(name: "spanish-main-navigation") {
          name
          links {
            label
            url {
              translate(language: ES) {
                path
              }
            }
            links {
              label
              url {
                translate(language: ES) {
                  path
                }
              }
              links {
                label
                url {
                  translate(language: ES) {
                    path
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const englishMenuItems = drupal.english.links;
  const spanishMenuItems = drupal.spanish.links;
  let displayMenu: DrupalLink[] = [];

  if (currentLanguage === 'en' && englishMenuItems) {
    displayMenu = englishMenuItems;
  } else if (currentLanguage === 'es' && spanishMenuItems) {
    displayMenu = spanishMenuItems;
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    if (navRef.current && !navRef.current.contains(target)) {
      setSubMenuOpen(false);
      setSelectedActive('');
      setHamburgerMenuToggle(false);
      isSearchOpen(false);
    }

    if (target.classList) {
      if (target.classList.contains('main-nav__nav-button')) {
        isSearchOpen(false);
      }
    }
  };

  const handleSelectItem = (item: string, index: number, selected?: string) => {
    const menuItem: DrupalLink = displayMenu[index];

    if (selected === item || selectedActive === item) {
      setSelectedActive('');
      setSubMenuOpen(false);
      selected = '';
      setSubMenuItems(menuItem);
    } else {
      setSelectedActive(item);
      setSubMenuOpen(true);
      selected = item;
      setSubMenuItems(menuItem);
    }

    return selected;
  };

  function toggleHamburgerMenu() {
    if (hamburgerMenuToggle) {
      setHamburgerMenuToggle(false);
      isSearchOpen(false);
    } else {
      setHamburgerMenuToggle(true);
      isSearchOpen(false);
    }
  }

  function toggleSearch() {
    if (searchOpen) {
      isSearchOpen(false);
      setSubMenuOpen(false);
      setSelectedActive('');
    } else {
      isSearchOpen(true);
      setHamburgerMenuToggle(false);
    }
  }

  return (
    <div ref={navRef}>
      <nav
        role="navigation"
        aria-label="Primary Navigation"
        className={
          'main-nav' + (hamburgerMenuToggle || searchOpen ? ' light-shadow hamburger-open' : '')
        }
      >
        <div className="main-nav__main-nav-links">
          <section className="main-nav__sectionOne">
            <div className="main-nav__home-container">
              <a
                href={translateLanguage(currentLanguage.toLowerCase(), '/', '/es/')}
                aria-label="Texas.gov home"
                className="main-nav__home-link"
              >
                <TexasIcon className="main-nav__icon" />
              </a>
            </div>

            <div className="main-nav__list-container">
              <ul aria-label="Navigation Menu" className="main-nav__list">
                {displayMenu &&
                  displayMenu.map((menuItem, index: number) => {
                    const menuItems = menuItem.links.length >= 1 ? true : false;
                    if (menuItems) {
                      return (
                        <li key={index} className="main-nav__list-items">
                          <button
                            onClick={() => handleSelectItem(menuItem.label, index)}
                            className={
                              'main-nav__nav-button' +
                              (selectedActive === menuItem.label ? ' active' : '')
                            }
                            aria-expanded={selectedActive === menuItem.label}
                            aria-label={menuItem.label}
                          >
                            {menuItem.label}
                          </button>
                        </li>
                      );
                    } else {
                      return (
                        <li key={index} className="main-nav__list-items">
                          <Link
                            to={menuItem.url.translate.path}
                            activeClassName={'active'}
                            className={
                              'main-nav__nav-button' +
                              (selectedActive === menuItem.label ? ' active' : '')
                            }
                            aria-expanded={selectedActive === menuItem.label}
                          >
                            {menuItem.label}
                          </Link>
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
          </section>

          <section className="main-nav__sectionTwo">
            <div className="main-nav__language-switcher">
              <LanguageSwitcher {...{ currentLanguage, translations }} />
            </div>

            <div className="main-nav__nav-search">
              {!searchOpen && (
                <button
                  className="search-icon__container"
                  onClick={toggleSearch}
                  aria-label="Search"
                >
                  <SearchIcon className="search-icon__icon" />
                </button>
              )}
              {searchOpen && (
                <button
                  className="search-icon__container"
                  onClick={toggleSearch}
                  aria-label="Close"
                >
                  <CloseIcon title="Close Search" className="search-icon__icon" />
                </button>
              )}
            </div>

            <button
              type="button"
              onClick={toggleHamburgerMenu}
              aria-label="Menu"
              aria-expanded={hamburgerMenuToggle}
              className="hamburger-wrapper"
            >
              <HamburgerIcon className="hamburger-menu" />
            </button>
          </section>
        </div>

        {!searchOpen && (
          <div className={'main-nav__sub-menu' + (subMenuOpen ? '' : ' menu-closed')}>
            {subMenuItems && (
              <>
                <div className="main-navigation__submenu-link-container">
                  {subMenuItems.links?.map((menuItem, index: number) => {
                    if (menuItem.links?.length === 0) {
                      return (
                        <Link
                          key={index}
                          to={menuItem.url.translate.path}
                          className="main-navigation__submenu-link"
                        >
                          {menuItem.label}
                        </Link>
                      );
                    }
                  })}
                </div>
                <div>
                  {subMenuItems.links?.map((menuItem, index) => {
                    if (menuItem.links && menuItem.links.length >= 1) {
                      return (
                        <div key={index}>
                          <p className="main-navigation__submenu_link-list-header">
                            {menuItem.label}
                          </p>
                          <ul className="main-navigation__submenu-link-list">
                            {menuItem.links?.map((item, index: number) => {
                              return (
                                <li key={index}>
                                  <Link to={item.url.translate.path}>{item.label}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    }
                  })}
                </div>
              </>
            )}
          </div>
        )}

        <div className="nav-search-desktop">
          {searchOpen && <NavSearch language={currentLanguage} />}
        </div>
      </nav>

      <div className="nav-search-mobile">
        {searchOpen && !hamburgerMenuToggle && <NavSearch language={currentLanguage} />}
      </div>

      <div className="mobile-nav-wrapper">
        {hamburgerMenuToggle && (
          <NavigationMenuMobile
            menuItems={displayMenu}
            handleSelectItem={handleSelectItem}
            toggleHamburgerMenu={toggleHamburgerMenu}
            {...{ currentLanguage, translations }}
          />
        )}
      </div>
    </div>
  );
}
