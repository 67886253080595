import React from 'react';

import './Banner.scss';

interface BannerProps {
  title: string;
  description?: string;
  linkTitle?: string;
  linkUrl?: string;
}

export default function Banner({ title, description, linkTitle, linkUrl }: BannerProps) {
  return (
    <div className="wrapper wrapper__child--blue">
      <div className="container">
        <section className="banner-child__details-container w-2/3">
          <h2 className="banner-child__title">{title}</h2>
          {description && <p className="banner-child__description">{description}</p>}
        </section>

        <section className="banner-child__link-container w-1/3">
          {linkTitle && linkUrl && (
            <a href={linkUrl} className="banner-child__link">
              {linkTitle}
            </a>
          )}
        </section>
      </div>
    </div>
  );
}
