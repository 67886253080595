import { graphql } from 'gatsby';

export const TexasNodeFragment = graphql`
  fragment TexasNode on Drupal_Node {
    title
    entityMetatags {
      key
      value
    }
    langcode {
      value
    }
    entityTranslations {
      entityId
      entityUrl {
        path
      }
      entityLanguage {
        id
        name
      }
    }
    entityUrl {
      path
      ... on Drupal_EntityCanonicalUrl {
        breadcrumb {
          url {
            path
          }
          text
        }
        path
      }
    }
  }
`;

export interface Metatag {
  key: string;
  value: string;
}

export interface TranslatedEntity {
  entityId: string;
  entityUrl: {
    path: string;
  };
  entityLanguage: {
    id: string;
    name: string;
  };
}

export interface Url {
  path: string;
}

export interface Breadcrumb {
  url: Url;
  text: string;
}

export interface EntityUrl {
  path: string;
  breadcrumb: Breadcrumb[];
}

export interface TexasNode {
  title: string;
  entityMetatags: Metatag[];
  langcode: {
    value: string;
  };
  entityTranslations: TranslatedEntity[];
  entityUrl: EntityUrl;
}

export const defaultBreadcrumb: Breadcrumb[] = [
  {
    url: {
      path: '/',
    },
    text: 'Home',
  },
];

export const defaultBreadcrumbSpanish: Breadcrumb[] = [
  {
    url: {
      path: '/es/',
    },
    text: 'Inicio',
  },
];
