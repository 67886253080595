import { FormFields } from './FormSchemas';

const headers = { 'Content-Type': 'application/json' };

export const FormSendMail = async (body: FormFields) => {
  let res;

  res = await fetch(process.env.GATSBY_SEND_EMAIL!, {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  }).then((res) => {
    if (res.status === 200) {
      return res.status;
    } else if (res.status === 401) {
      return res.status;
    } else {
      res.status;
    }
  });

  return res;
};
