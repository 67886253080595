import React from 'react';
import { Link } from '../Interfaces/Generics';
import LinksHelper from '../utils/LinksHelper';
import './QuickLinks.scss';

interface QuickLinksProps {
  currentLanguage: string;
  description: string;
  popularTopics: Link[];
  relatedAgencies?: Link[];
}

export default function QuickLinks({
  description,
  currentLanguage,
  popularTopics,
  relatedAgencies,
}: QuickLinksProps) {
  return (
    <div className="quick-links__wrapper">
      <div className="quick-links__container">
        <section className="w-2/3">
          <h2 className="quick-links__title">
            {currentLanguage === 'en' ? 'Quick Links' : 'Enlaces Rápidos'}
          </h2>
          <p className="quick-links__description">{description}</p>
        </section>

        <div className="quick-links__links-container">
          <section className="quick-links__link-container w-2/3">
            <h3 className="quick-links__subtitles">
              {currentLanguage === 'en' ? 'Popular Topics' : 'Temas Populares'}
            </h3>

            <ul aria-label="Popular Topics" className="quick-links__list flex-row-wrap m-0">
              {popularTopics.map((item, index) => (
                <li key={index} className="quick-links__list-item w-1/2">
                  <LinksHelper key={index} linkTitle={item.title} linkUrl={item.url.path} />
                </li>
              ))}
            </ul>
          </section>

          {relatedAgencies && (
            <section className="quick-links__related-agencies w-1/3">
              <div className="quick-links__link-container--border"></div>
              <div className="quick-links__link-container">
                <h3 className="quick-links__subtitles">
                  {currentLanguage === 'en' ? 'Related Agencies' : 'Agencias Relacionadas'}
                </h3>

                <ul aria-label="Related Agencies" className="quick-links__list m-0 p-0">
                  {relatedAgencies.map((item, index) => (
                    <li key={index} className="quick-links__list-item">
                      <LinksHelper key={index} linkTitle={item.title} linkUrl={item.url.path} />
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  );
}
