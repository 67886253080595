import React, { useState, useEffect } from 'react';
import xss from 'xss';

import LinksHelper from '../utils/LinksHelper';
import Accordion from '../Generics/Accordion';

import classNames from 'classnames';

import './TopicsMenu.scss';

interface CardAttributeProps {
  hasBorder: boolean;
}

interface TopicsMenuProps {
  cards: TopicsCardProps[];
  title: string;
  description: string;
}

interface TopicsCardProps {
  title: string;
  description: string;
  linkTitle: string;
  linkUrl: string;
}

type TopicsMenuCardProps = TopicsCardProps & CardAttributeProps;

function TopicsMenuCard({
  title,
  description,
  linkTitle,
  linkUrl,
  hasBorder,
}: TopicsMenuCardProps) {
  return (
    <div
      className={classNames('topics-menu-card w-1/3', {
        'topics-menu-card--border': hasBorder,
      })}
    >
      <div>
        <h3 className="topics-menu-card__title">{title}</h3>

        <div
          className="topics-menu-card__description"
          dangerouslySetInnerHTML={{
            __html: xss(description),
          }}
        />
      </div>

      <div className="topics-menu-card__link-container">
        <LinksHelper linkTitle={linkTitle} linkUrl={linkUrl} />
      </div>
    </div>
  );
}

export default function TopicsMenu(props: TopicsMenuProps) {
  const [hasBorder, setBorder] = useState(false);

  useEffect(() => {
    if (props.cards.length > 3) {
      setBorder(true);
    }
  }, []);

  return (
    <div className="wrapper wrapper--white">
      <div className="container container--column">
        <section className="topics-menu__header w-2/3">
          <h2 className="topics-menu__title mt-0">{props.title}</h2>
          <p className="topics-menu__description">{props.description}</p>
        </section>

        <section className="topics-menu__card-container">
          {props.cards.map((card, index) => (
            <TopicsMenuCard key={index} {...card} hasBorder={hasBorder} />
          ))}
        </section>

        <section className="topics-menu__accordion-container">
          {props.cards.map((card, index) => (
            <Accordion key={index} id={index} title={card.title} isOpen={false}>
              <div
                dangerouslySetInnerHTML={{ __html: xss(card.description) }}
                className="accordion__text"
              />
              <LinksHelper linkTitle={card.linkTitle} linkUrl={card.linkUrl} />
            </Accordion>
          ))}
        </section>
      </div>
    </div>
  );
}
