import React from 'react';
import xss from 'xss';

import { useStaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';

import Layout from '../components/Layout';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import { TexasNode } from '../fragments/texas-node';
import Breadcrumbs from '../components/Generics/Breadcrumbs';
import { FluidObject } from 'src/components/Interfaces/Generics';

import { TranslatedEntity } from '../fragments/texas-node';

import './404.scss';

interface NotFoundPage extends TexasNode {
  fieldDescription: {
    value: string;
  };
  fieldLinkHeading: {
    value: string;
  };
  fieldPopularTopics: {
    title: string;
    url: {
      path: string;
    };
  }[];
}

interface NotFoundPageData {
  image: {
    publicURL: string;
    relativePath: string;
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  drupal: {
    pages: {
      entities: NotFoundPage[];
    };
  };
}

export default function Error() {
  const data = useStaticQuery<NotFoundPageData>(graphql`
    query {
      image: file(relativePath: { eq: "images/txgov_404.png" }) {
        publicURL
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drupal {
        pages: nodeQuery(
          filter: { conditions: [{ field: "type", value: "404_page", operator: EQUAL }] }
        ) {
          entities(language: EN) {
            ...TexasNode
            ... on Drupal_Node404Page {
              fieldDescription {
                value
              }
              fieldLinkHeading {
                value
              }
              fieldPopularTopics {
                title
                url {
                  path
                }
              }
            }
          }
        }
      }
    }
  `);
  const image = data.image;
  const page = data.drupal.pages.entities[0];

  const translations: TranslatedEntity[] = [
    {
      entityLanguage: { id: 'en', name: 'English' },
      entityUrl: { path: '/404' },
      entityId: '',
    },
    {
      entityLanguage: { id: 'es', name: 'Spanish' },
      entityUrl: { path: '/es/404' },
      entityId: '',
    },
  ];

  return (
    <Layout currentLanguage={page.langcode.value} translations={translations}>
      <SEO
        metatags={page.entityMetatags}
        language="en"
        breadCrumbInfo={{
          breadcrumb: page.entityUrl.breadcrumb,
          currentPath: '/404',
          currentTitle: '404',
        }}
        entityTranslations={page.entityTranslations}
      />

      <div className="wrapper wrapper--grey">
        <div className="container container--reverse">
          <section className="w-1/2">
            <Breadcrumbs breadcrumb={page.entityUrl.breadcrumb} currentPageTitle={'404'} darkText />
            <h1 className="container-404__heading">{page.title}</h1>
            <div
              className="container-404__desc"
              dangerouslySetInnerHTML={{
                __html: xss(page.fieldDescription.value),
              }}
            />
          </section>

          <section className="w-1/2 container-404__error-image-container">
            <Img fluid={image.childImageSharp.fluid} alt="boots stuck in gum" />
          </section>
        </div>
      </div>

      <div className="wrapper wrapper--white">
        <div className="container container--column">
          <h2
            className="container-404__desc-2"
            dangerouslySetInnerHTML={{ __html: xss(page.fieldLinkHeading.value) }}
          />

          <ul className="container-404__browse-list">
            {page.fieldPopularTopics.map((link, index) => (
              <li key={index} className="container-404__browse-list--row">
                <Link to={link.url.path} className="container-404__link-text">
                  {link.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
}
