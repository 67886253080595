import React from 'react';
import xss from 'xss';

import { Link } from '../Interfaces/Generics';
import { PostType } from '../utils/enums';
import LinksHelper from '../utils/LinksHelper';
import HomepageIcons from './HomepageIcons';

import './TopicMenuCard.scss';

interface TopicMenuCardProps {
  title: string;
  description: string;
  postType?: PostType;
  link: Link;
  language: string;
}

export default function TopicMenuCard(props: TopicMenuCardProps) {
  return (
    <>
      <div className="w-1/2 topic-menu-card">
        <section className="topic-menu-card__details-container">
          {props.postType && <HomepageIcons postType={props.postType} language={props.language} />}
          <h2 className="topic-menu-card__title">{props.title}</h2>
          <div
            className="topic-menu-card__description"
            dangerouslySetInnerHTML={{ __html: xss(props.description) }}
          ></div>
          <LinksHelper
            linkTitle={props.link.title}
            linkUrl={props.link.url.path}
            linkClassname={'topic-menu-card__link'}
          />
        </section>
      </div>
    </>
  );
}
