import { Node } from 'gatsby';
import { ChildImageSharpType } from './components/Interfaces/Generics';

import { LunrQuery, LunrQueryOpts } from './lunr-query';

export enum DrupalNodeType {
  ChildPage = 'Drupal_NodeChildPage',
  MainLandingPage = 'Drupal_NodeMainLandingContentType',
  TexansOfTexas = 'Drupal_NodeTexansOfTexas',
  Service = 'Drupal_TaxonomyTermServices',
  Agency = 'Drupal_TaxonomyTermAgencies',
  County = 'Drupal_TaxonomyTermCounties',
}

export enum SearchType {
  GlobalSearch,
  AgencySearch,
  ServiceSearch,
}

export interface DruaplNode {
  __typename: DrupalNodeType;
  entityLabel: string;
  entityId: string;
  entityLanguage: {
    id: string;
  };
}

export interface PageNode {
  __typename:
    | DrupalNodeType.ChildPage
    | DrupalNodeType.MainLandingPage
    | DrupalNodeType.TexansOfTexas;

  fieldDescription: { value: string };
  entityUrl: { path: string };
}

export interface AgencyNode {
  __typename: DrupalNodeType.Agency;
  fieldLogo: {
    alt: string;
    entity: {
      localFile: {
        childImageSharp: ChildImageSharpType;
      };
    };
  };
  fieldPhone: string;
  fieldEmail: string;
  fieldMailingAddress: { value: string };
  fieldAgencyLink: { uri: string };
  fieldAgencyType: {
    entity: {
      entityId: string;
      name: string;
    };
  }[];
}

export interface ServiceNode {
  __typename: DrupalNodeType.Service;
  fieldServiceType: { entity: { entityId: string } }[];
  fieldServiceLink: { url: { path: string } };
}

export interface CountyNode {
  __typename: DrupalNodeType.County;
  fieldCountyLink: { url: { path: string } };
}

export type SearchResult = Node & DruaplNode & (PageNode | AgencyNode | ServiceNode | CountyNode);

let searchWorker: Worker | null;

function getSearchWorker() {
  if (!searchWorker) {
    searchWorker = new Worker('./search.worker.ts', { type: 'module' });
  }

  return searchWorker;
}

let jobNumber = 0; // Track job number so we know what is coming in and out of the worker

/** The query object we send to the worker */
export interface SearchPayload {
  language: string;
  jobNumber: number;
  query: string;
  pageSize?: number;
  currentPage?: number;
  typeFilter?: string;
  filters?: string[];
}

/** The response object we expect back from the worker */
export interface SearchResponse {
  jobNumber: number;
  results: SearchResult[];
  totalResults: number;
}

export interface SearchOptions {
  pageSize?: number;
  currentPage?: number;
  typeFilter?: string;
  filters?: string[];
}

export function search(
  language: string,
  query: LunrQueryOpts[],
  options: SearchOptions = {}
): Promise<SearchResponse> {
  const currentJobNumber = jobNumber; // track current job number

  jobNumber++; // increment job number for next time

  return new Promise((resolve) => {
    function cb({ data }: MessageEvent<SearchResponse>) {
      // If the job number from the worker matches the one we sent for this query
      if (data.jobNumber === currentJobNumber) {
        // stop listening for worker messages messages
        getSearchWorker().removeEventListener('message', cb);

        // resolve promise with our search results
        resolve(data);
      }
    }

    // Listen for worker messages
    getSearchWorker().addEventListener('message', cb);

    const payload: SearchPayload = {
      language,
      jobNumber: currentJobNumber,
      query: new LunrQuery(query).toString(),
      pageSize: options.pageSize,
      currentPage: options.currentPage,
      typeFilter: options.typeFilter,
      filters: options.filters,
    };

    // send query to worker
    getSearchWorker().postMessage(payload);
  });
}
