import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

import { Breadcrumb, Metatag, TranslatedEntity } from '../fragments/texas-node';

interface SEOProps {
  metatags: Metatag[];
  language: string;
  breadCrumbInfo?: BreadCrumbSEO;
  entityTranslations: TranslatedEntity[];
}

export interface BreadCrumbSEO {
  breadcrumb: Breadcrumb[];
  currentPath: string;
  currentTitle: string;
}

export function createBreadcrumbData(breadcrumbInfo: BreadCrumbSEO): string {
  const breadCrumbList = breadcrumbInfo.breadcrumb?.map((crumb, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    item: {
      '@id': `${crumb.url.path}`,
      name: `${crumb.text}`,
    },
  }));

  breadCrumbList.push({
    '@type': 'ListItem',
    position: breadCrumbList.length + 1,
    item: {
      '@id': `${breadcrumbInfo.currentPath}`,
      name: `${breadcrumbInfo.currentTitle}`,
    },
  });

  const breadcrumbData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadCrumbList,
  };

  return JSON.stringify(breadcrumbData);
}

export default function SEO({
  metatags,
  language,
  breadCrumbInfo,
  entityTranslations,
  children,
}: PropsWithChildren<SEOProps>) {
  const tags = new Map(metatags.map((tag) => [tag.key, tag.value])); // a map of tags for easier lookup
  const breadcrumbData = breadCrumbInfo ? createBreadcrumbData(breadCrumbInfo) : '';
  const defaultTranslation = entityTranslations?.find(
    (translation) => translation.entityLanguage.id === 'en'
  );
  const validateUrl = new RegExp(
    /^((((https?|ftps?|gopher|telnet|nntp):\/\/)|(mailto:|news:))(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)([).!';/?:,][[:blank:|:blank:]])?$/
  );
  const url = process.env.GATSBY_SITE_URL;
  const cleanURL = url?.match(validateUrl);
  const gatsbySiteUrl = cleanURL && process.env.GATSBY_SITE_URL;

  return (
    <Helmet htmlAttributes={{ lang: language }} title={tags.get('title')}>
      <meta property="og:site_name" content={tags.get('og:site_name')} />
      <meta property="og:type" content={tags.get('og:type')} />
      <meta property="og:title" content={tags.get('og:title')} />
      <meta property="og:description" content={tags.get('og:description')} />
      <meta property="og:url" content={process.env.GATSBY_SITE_URL} />
      <meta name="og:image" content={""} />
      <meta property="og:image:alt" content={tags.get('og:image:alt')} />
      <meta name="description" content={tags.get('description')} />
      <meta name="google-site-verification" content={process.env.GATSBY_GOOGLE_SITE_VERIFICATION} />
      {/* hreflang */}
      {entityTranslations?.map((translation, i) => (
        <link
          key={i}
          rel="alternative"
          href={gatsbySiteUrl + translation.entityUrl.path}
          hrefLang={translation.entityLanguage.id}
        />
      ))}
      {defaultTranslation && (
        <link
          rel="alternative"
          href={gatsbySiteUrl + defaultTranslation.entityUrl.path}
          hrefLang="x-default"
        />
      )}
      <script type="application/ld+json">{breadcrumbData}</script>
      {children}
    </Helmet>
  );
}
