import React from 'react';
import { Breadcrumb } from '../../fragments/texas-node';
import RightArrowIcon from '../Icons/RightArrowIcon';

import './Breadcrumbs.scss';

interface BreadcrumbsProps {
  breadcrumb: Breadcrumb[];
  currentPageTitle: string;
  darkText?: boolean;
}

export default function Breadcrumbs({
  breadcrumb,
  currentPageTitle,
  darkText = false,
}: BreadcrumbsProps) {
  return (
    <nav aria-label="Breadcrumb" className={'breadcrumbs ' + (darkText ? 'breadcrumbs-dark' : '')}>
      <ul className="breadcrumbs__list">
        {breadcrumb.map((crumb, i) => (
          <li key={i} className="breadcrumbs__list-item">
            <a href={crumb.url.path} className="breadcrumbs__link">
              {crumb.url.path === '/es/' ? 'Inicio' : crumb.text}
            </a>
            <RightArrowIcon className="breadcrumbs__icon" />
          </li>
        ))}
        <li className="breadcrumbs__list-item">{currentPageTitle}</li>
      </ul>
    </nav>
  );
}
