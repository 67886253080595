export interface DefaultLunrQueryOpts {
  query: string;
  field?: string;
  fuzzy?: number;
}

export type LunrQueryOpts =
  | string
  | (DefaultLunrQueryOpts & ({ required?: boolean } | { exclude?: boolean }));

/**
 * Builds a Lunr compatible query string
 */
export class LunrQuery {
  private cache?: string;

  constructor(private queryParts: LunrQueryOpts[] = []) {}

  toString(): string {
    if (this.cache) {
      return this.cache;
    }

    const query: string[] = this.queryParts.map((opts) => {
      if (typeof opts === 'string') {
        return opts.trim();
      }

      const parsed = opts.query.trim().split(' ');

      let val = opts.field ? parsed.map((q) => `${opts.field}:${q}`) : parsed;

      if ('required' in opts && opts.required) {
        val = val.map((q) => '+' + q);
      } else if ('exclude' in opts && opts.exclude) {
        val = val.map((q) => '-' + q);
      }

      const { fuzzy } = opts;

      if (fuzzy) {
        // individual tokens must be at least twice as long as the fuzzy option
        val = val.map((q) => (q.length > fuzzy * 2 ? `${q}~${opts.fuzzy}` : q));
      }

      return val.join(' ');
    });

    return query.join(' ');
  }
}
