export const formText = {
  en_name_label: 'Name',
  es_name_label: 'Nombre',
  en_email_label: 'Email',
  es_email_label: 'Correo electrónico',
  en_phone_label: 'Phone',
  es_phone_label: 'Teléfono',
  en_reason_label: 'Select a reason for contacting',
  es_reason_label: 'Seleccione un motivo para comunicarse',
  en_reason_placeholder: 'Select one',
  es_reason_placeholder: 'Seleccione una opción',
  en_service_label: 'What Texas.gov service were you using?',
  es_service_label: '¿Qué servicio de Texas.gov estaba utilizando?',
  en_service_placeholder:
    'e.g., TxT, vehicle registration renewal, driver or license ID renewal, etc.',
  es_service_placeholder:
    'P. ej., TxT, renovación de registro de vehículo, renovación de licencia de conducir o identificación, etc.',
  en_describe_label: 'Please describe the issue',
  es_describe_label: 'Describa el problema',
  en_describe_placeholder:
    'e.g. error message details, payment issue information (including payment date), etc.',
  es_describe_placeholder:
    'P. ej., detalles del mensaje de error, información del problema de pago (incluida la fecha de pago), etc.',
  en_submit_label: 'Submit',
  es_submit_label: 'Enviar',
  en_optional: 'Optional',
  es_optional: 'Opcional',
  en_new_form_button_label: 'Submit a new technical issue',
  es_new_form_button_label: 'Enviar un nuevo problema técnico',
  en_email_error_yellow_header: 'Error submitting form',
  es_email_error_yellow_header: 'Error al enviar el formulario',
  en_email_error_yellow_message: 'Your message has not been sent. Please try again.',
  es_email_error_yellow_message: 'Su mensaje no ha sido enviado. Inténtalo de nuevo.',
  en_email_error_red_header: 'Error submitting form',
  es_email_error_red_header: 'Error al enviar el formulario',
  en_email_error_red_message: 'Unable to submit form. Please try again later.',
  es_email_error_red_message:
    'No se puede enviar el formulario. Por favor, inténtelo de nuevo más tarde.',
};

export const dropdownListItemsEnglish = [
  {
    label: 'I need technical support with the Texas.gov website or services',
    value: 'TECHSUPPORT',
  },
  {
    label: 'I have a payment issue with a Texas.gov service',
    value: 'PYMNTISSUE',
  },
  {
    label: 'I have a question about the Texas.gov program',
    value: 'TXGOVPROGRAM',
  },
  {
    label: 'Other',
    value: 'OTHR',
  },
];

export const dropdownListItemsSpanish = [
  {
    label: 'Necesito asistencia técnica con el sitio web o los servicios de Texas.gov',
    value: 'TECHSUPPORT',
  },
  {
    label: 'Tengo un problema de pago con un servicio de Texas.gov',
    value: 'PYMNTISSUE',
  },
  {
    label: 'Tengo una pregunta sobre el programa Texas.gov',
    value: 'TXGOVPROGRAM',
  },
  {
    label: 'Otra',
    value: 'OTHR',
  },
];

export const errorMessage = {
  en_required: 'This field is required.',
  es_required: 'Este campo es obligatorio.',
  en_name_min: 'Must be at least 2 characters or more.',
  es_name_min: 'Debe tener al menos 2 caracteres o más.',
  en_name_max: 'Name must be less than 63 characters.',
  es_name_max: 'El nombre debe tener menos de 63 caracteres.',
  en_name_allowed: 'Name must only contain letters. ',
  es_name_allowed: 'El nombre solo debe contener letras.',
  en_max_service_textarea: 'Must be 250 characters or less.',
  es_max_service_textarea: 'Debe tener 250 caracteres o menos.',
  en_max_description_textarea: 'Must be 500 characters or less.',
  es_max_description_textarea: 'Debe tener 500 caracteres o menos.',
  en_email: 'Please enter an email in the following format: Name@domain.com',
  en_email_max: 'Email must be less than 55 characters.',
  es_email: 'Por favor ingrese un correo electrónico en el siguiente formato: Nombre@dominio.com',
  es_email_max: 'El correo electrónico debe tener menos de 55 caracteres.',
  en_min_phone: 'Phone must be 10 digits.',
  es_min_phone: 'El teléfono debe tener 10 dígitos.',
  en_max_phone: 'Phone must be 10 digits.',
  es_max_phone: 'El teléfono debe tener 10 dígitos.',
};
