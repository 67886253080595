import { PageProps, graphql } from 'gatsby';
import React from 'react';

import { ChildImageSharpType } from '../components/Interfaces/Generics';
import { TexasNode } from '../fragments/texas-node';
import Hero from '../components/Directory/Hero';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { cleanPaginatedMetatags } from '../components/Directory/util';
import AgenciesDirectory from '../components/Directory/AgenciesDirectory';

export interface Agency {
  fieldLogo: {
    alt: string;
    url: string;
    title: string;
    height: number;
    width: number;
    entity: {
      localFile: ChildImageSharpType;
    };
  };
  entityId: string;
  entityLabel: string;
  fieldEmail: string;
  fieldPhone: string;
  fieldMailingAddress: {
    value: string;
  };
  fieldAgencyLink: {
    uri: string;
  };
  fieldAgencyType: {
    entity: {
      entityId: string;
      name: string;
    };
  }[];
  fieldServices: {
    entity: {
      name: string;
    };
  }[];
}

export interface AgencyTypes {
  entityTranslation: {
    entityLabel: string;
    entityId: string;
    agencyOfType: {
      entities: {
        entityTranslation: {
          entityLabel: string;
        };
      }[];
    };
  };
}
export interface AgenciesPage extends TexasNode {
  body: {
    value: string;
  };
}
export interface AgenciesData {
  drupal: {
    page: AgenciesPage;
    agencies: {
      entities: Agency[];
    };
    agencyTypes: {
      entities: AgencyTypes[];
    };
  };
}

export const query = graphql`
  query($id: String!, $offset: Int!, $limit: Int!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ...TexasNode
        ... on Drupal_NodeAgenciesPage {
          body {
            value
          }
        }
      }

      agencies: taxonomyTermQuery(
        limit: $limit
        offset: $offset
        filter: { conditions: [{ field: "vid", value: "agencies", operator: EQUAL }] }
      ) {
        entities {
          ... on Drupal_TaxonomyTermAgencies {
            fieldLogo {
              alt
              url
              title
              height
              width
              entity {
                url
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          entityId
          entityLabel
          ... on Drupal_TaxonomyTermAgencies {
            fieldPhone
            fieldEmail
            fieldMailingAddress {
              value
            }

            fieldAgencyLink {
              uri
            }
            fieldAgencyType {
              entity {
                entityId
                name
              }
            }
            fieldServices {
              entity {
                name
              }
            }
          }
        }
      }

      agencyTypes: taxonomyTermQuery(
        filter: { conditions: [{ field: "vid", value: "agency_type", operator: EQUAL }] }
        limit: 1000
      ) {
        entities {
          entityTranslation(language: $language) {
            ... on Drupal_TaxonomyTermAgencyType {
              entityLabel
              entityId
              agencyOfType: reverseFieldAgencyTypeTaxonomyTerm(
                filter: { conditions: { field: "vid", value: "agencies", operator: EQUAL } }
              ) {
                entities {
                  entityTranslation(language: $language) {
                    entityLabel
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface AgenciesCtx {
  id: string;
  offset: number;
  pageNumber: number;
  limit: number;
  language: string;
  totalItems: number;
}

export default function Agencies({
  data: {
    drupal: { page, agencies, agencyTypes },
  },
  pageContext,
}: PageProps<AgenciesData, AgenciesCtx>) {
  const metaTags = cleanPaginatedMetatags(page.entityMetatags, pageContext.pageNumber);

  return (
    <Layout currentLanguage={page.langcode.value} translations={page.entityTranslations}>
      <SEO
        metatags={metaTags}
        language={page.langcode.value}
        entityTranslations={page.entityTranslations}
      />

      <Hero title={page.title} description={page.body.value} language={page.langcode.value} />

      <AgenciesDirectory
        cards={agencies.entities}
        filter={agencyTypes.entities}
        pageContext={{
          ...pageContext,
          path: page.entityUrl.path,
        }}
        currentLanguage={page.langcode.value}
      />
    </Layout>
  );
}
