import React, { ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import LinksHelper from './utils/LinksHelper';
import FacebookIcon from '../svg/facebook.svg';
import TwitterIcon from '../svg/twitter.svg';
import InstagramIcon from '../svg/instagram.svg';

import './Footer.scss';

interface MenuItem {
  label: string;
  url: {
    path: string;
  };
}

interface SocialMenuItems {
  links: MenuItem[];
}

interface MenuItems {
  links: {
    label: string;
    links: MenuItem[];
  }[];
}

interface MenuItemsData {
  drupal: {
    footerEnglish: MenuItems;
    footerSpanish: MenuItems;
    social: SocialMenuItems;
  };
}

export interface FooterProps {
  currentLanguage?: string;
}

enum SocialLink {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Instagram = 'instagram',
}

export function socialMediaIcons(socialMediaItems: MenuItem[]): ReactElement[] {
  return socialMediaItems.map(
    (item, index): ReactElement => {
      switch (item.label.toLowerCase() as SocialLink) {
        case SocialLink.Facebook:
          return (
            <a
              key={index}
              href={item.url.path}
              target="__blank"
              className="footer__social-media-icon"
              aria-describedby="facebook-icon facebook-icon-description"
            >
              <FacebookIcon data-testid="facebook-icon" />
            </a>
          );

        case SocialLink.Twitter:
          return (
            <a
              key={index}
              href={item.url.path}
              target="__blank"
              className="footer__social-media-icon"
              aria-describedby="twitter-icon twitter-icon-description"
            >
              <TwitterIcon data-testid="twitter-icon" />
            </a>
          );

        case SocialLink.Instagram:
          return (
            <a
              key={index}
              href={item.url.path}
              target="__blank"
              className="footer__social-media-icon"
              aria-describedby="instagram-icon instagram-icon-description"
            >
              <InstagramIcon data-testid="instagram-icon" />
            </a>
          );
      }
    }
  );
}

export default function Footer({ currentLanguage }: FooterProps) {
  const { drupal } = useStaticQuery<MenuItemsData>(graphql`
    query {
      drupal {
        footerEnglish: menuByName(name: "footer", language: EN) {
          links {
            label
            links {
              label
              url {
                path
              }
            }
          }
        }
        footerSpanish: menuByName(name: "spanish-footer-menu", language: ES) {
          links {
            label
            links {
              label
              url {
                path
              }
            }
          }
        }
        social: menuByName(name: "social-media-links", language: EN) {
          links {
            label
            url {
              path
            }
          }
        }
      }
    }
  `);

  function returnSecondaryMenuItems(secondaryLinks: MenuItem[]) {
    return secondaryLinks.map((link, index) => {
      return (
        <li className="footer__list-item" key={index}>
          <LinksHelper linkExternalIcon={false} linkTitle={link.label} linkUrl={link.url.path} />
        </li>
      );
    });
  }

  function returnCurrentMenu() {
    if (currentLanguage === 'en') {
      return drupal.footerEnglish.links.map((footerItem, index) => {
        return (
          <div key={index} className="footer__list">
            <h4>{footerItem.label}</h4>
            <ul>{returnSecondaryMenuItems(footerItem.links)}</ul>
          </div>
        );
      });
    }

    if (currentLanguage === 'es') {
      return drupal.footerSpanish.links.map((footerItem, index) => {
        return (
          <div key={index} className="footer__list">
            <h4>{footerItem.label}</h4>
            <ul>{returnSecondaryMenuItems(footerItem.links)}</ul>
          </div>
        );
      });
    }
  }

  return (
    <footer className="wrapper wrapper--footer">
      <nav className="container" aria-label="Footer Navigation">
        <section className="footer__link-container">{returnCurrentMenu()}</section>

        <section className="footer__copy-container">
          <p className="footer__copyright">
            Copyright &copy;{new Date().getFullYear()} State of Texas. All rights reserved.
          </p>

          <div className="footer__social-icons">{socialMediaIcons(drupal.social.links)}</div>
        </section>
      </nav>
    </footer>
  );
}
