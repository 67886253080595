import React from 'react';

interface IconProps {
  className?: string;
}

export default function RightArrowIcon(props: IconProps) {
  return (
    <svg {...props} role="img" className={props.className} aria-hidden="true">
      <title>Right Arrow Icon</title>
      <path
        d="M4.05719096,5 L0.528595479,8.52859548 C0.268245951,8.78894501 0.268245951,9.21105499 0.528595479,9.47140452 C0.788945007,9.73175405 1.21105499,9.73175405 1.47140452,9.47140452 L5.47140452,5.47140452 C5.73175405,5.21105499 5.73175405,4.78894501 5.47140452,4.52859548 L1.47140452,0.528595479 C1.21105499,0.268245951 0.788945007,0.268245951 0.528595479,0.528595479 C0.268245951,0.788945007 0.268245951,1.21105499 0.528595479,1.47140452 L4.05719096,5 Z"
        id="path-1"
      ></path>
    </svg>
  );
}
