import React from 'react';

import './FeaturedBanner.scss';

interface FeaturedBannerProps {
  title: string;
  description?: string;
  linkTitle: string;
  linkUrl: string;
}

export default function FeaturedBanner({
  title,
  description,
  linkTitle,
  linkUrl,
}: FeaturedBannerProps) {
  return (
    <div className="wrapper">
      <div className="container">
        <section className="banner__details-container w-2/3">
          <h2 className="banner__title">{title}</h2>
          {description && <p className="banner__description">{description}</p>}
        </section>

        <section className="banner__link-container w-1/3">
          <a href={linkUrl} className="banner__link pt-5">
            {linkTitle}
          </a>
        </section>
      </div>
    </div>
  );
}
