import React, { useState, PropsWithChildren } from 'react';
import MinusIcon from '../Icons/MinusIcon';
import PlusIcon from '../Icons/PlusIcon';
import { transformToId } from '../utils/UtilityFunctions';

import classNames from 'classnames';

import './Accordion.scss';

interface AccordionProps {
  id: number;
  title: string;
  isOpen: boolean;
}

export default function Accordion({
  id,
  title,
  isOpen,
  children,
}: PropsWithChildren<AccordionProps>) {
  const [toggleOpen, setToggleOpen] = useState(isOpen);

  function toggleAccordion() {
    setToggleOpen(!toggleOpen);
  }

  return (
    <div className="accordion">
      <section
        tabIndex={0}
        role="button"
        id={`id__${id}`}
        onClick={toggleAccordion}
        aria-expanded={toggleOpen}
        aria-controls={title}
        onKeyDown={(e) => {
          switch (e.key) {
            case ' ':
            case 'Enter':
              toggleAccordion();
              break;
            default:
          }
        }}
      >
        <div className="accordion__header-container" id={transformToId(title)}>
          <h3
            className={classNames('accordion__title', {
              'accordion__title--is-active': toggleOpen,
            })}
          >
            {title}
          </h3>
          {toggleOpen ? (
            <MinusIcon
              data-testid="minus-icon"
              className={classNames('accordion__icon-minus accordion__icon', {
                'accordion__icon-minus--is-active': toggleOpen,
              })}
            />
          ) : (
            <PlusIcon data-testid="plus-icon" className="accordion__icon" />
          )}
        </div>
      </section>
      {toggleOpen && <section className="accordion__content">{children}</section>}
    </div>
  );
}
