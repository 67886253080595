import React from 'react';
import Select from 'react-select';

interface SelectProps {
  id: string;
  onChange: any;
  onBlur: any;
  options: OptionsType[];
  myKey: string;
  value: string;
  className: string;
  placeholder: string;
  components: any;
  classNamePrefix: string;
  styles: any;
  checkValueChange: any;
}

export interface OptionsType {
  label: string;
  value: string;
}

export default function CustomSelect({
  id,
  myKey,
  onChange,
  onBlur,
  options,
  value,
  placeholder,
  components,
  checkValueChange,
}: SelectProps) {
  const defaultValue = (options: OptionsType[], value: string) => {
    return options ? options.find((option: OptionsType) => option.value === value) : '';
  };
  const defaultLabel = (options: OptionsType[], label: string) => {
    return options ? options.find((option: OptionsType) => option.label === label) : '';
  };
  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 65,
      minHeight: 55,
      '&:hover': { borderColor: checkValueChange ? '#d2082d' : '#dcdee1' },
      border: checkValueChange ? '2px solid #d2082d' : '2px solid #dcdee1',
      borderBottomLeftRadius: checkValueChange ? 0 : 4,
      borderBottomRightRadius: checkValueChange ? 0 : 4,
      boxShadow: 'none',
    }),

    menu: (provided: any) => ({
      ...provided,
      marginTop: 0,
      color: '#47525D',
      fontFamily: 'Work Sans',
      fontSize: 16,
      border: '1px solid #dcdee1',
      borderTopRightRadius: 1,
      borderTopLeftRadius: 1,
    }),

    option: (provided: any) => ({
      ...provided,
      paddingTop: 12,
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#47525d',
    }),
  };

  return (
    <div>
      <Select
        id={id}
        key={`my_unique_select_key__${defaultLabel(options, myKey)}`}
        styles={customStyles}
        value={defaultValue(options, value)}
        onChange={(value) => {
          onChange(value);
        }}
        onBlur={(value) => {
          onBlur(value);
        }}
        options={options}
        placeholder={placeholder}
        components={components}
        aria-label={id}
      />
    </div>
  );
}
