import React from 'react';
import xss from 'xss';

import Img from 'gatsby-image';

import './FeaturedPost.scss';
import { Link } from '../Interfaces/Generics';
import LinksHelper from '../utils/LinksHelper';

interface FluidObject {
  aspectRatio: number;
  base64: string;
  originalImg: string;
  originalName: string;
  presentationHeight: number;
  presentationWidth: number;
  sizes: string;
  src: string;
  srcSet: string;
  srcSetWebp: string;
  srcWebp: string;
  tracedSVG: string;
}

interface FeaturedPostProps {
  optional?: boolean;
  title: string;
  description: string;
  image?: { alt: string; url: FluidObject };
  link: Link;
  caption?: string;
}

export default function FeaturedPost({
  title,
  description,
  image,
  link,
  optional,
  caption,
}: FeaturedPostProps) {
  return (
    <>
      {!optional ? (
        <div className="wrapper wrapper--grey">
          <div className="container container--reverse">
            <section className="w-2/3">
              <h2 className="post__title mt-0">{title}</h2>

              <p className="post__description">{description}</p>

              <LinksHelper
                linkUrl={link.url.path}
                linkTitle={link.title}
                linkClassname="post__link"
              />
            </section>
            <section className="w-1/3 post__image-container">
              {image && (
                <figure>
                  <Img className="post__image" fluid={image.url} alt={image.alt} />
                  {caption && <figcaption dangerouslySetInnerHTML={{ __html: xss(caption) }} />}
                </figure>
              )}
            </section>
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <div className="container container--reverse">
            <section className="post__optional-alignment">
              <h2 className="w-2/3 post__title-optional mt-0">{title}</h2>

              <div className="post__optional-container">
                <p className="w-2/3 post__description-optional mt-0 mb-0">{description}</p>

                <LinksHelper
                  linkUrl={link.url.path}
                  linkTitle={link.title}
                  linkClassname="post__link w-1/3"
                />
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
}
