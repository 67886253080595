import { Link } from 'gatsby';
import React, { useState } from 'react';
import CloseIcon from './Icons/CloseIcon';
import { LanguageSwitcherMobile } from './LanguageSwitcher';
import { LanguageSwitcherProps } from './LanguageSwitcher';
import { DrupalLink } from './Navigation';

import './NavigationMenuMobile.scss';

interface MobileMenuProps {
  menuItems: DrupalLink[];
  handleSelectItem: (item: string, index: number, selected?: string) => string;
  toggleHamburgerMenu: () => void;
}

type NavigationMenuMobileProps = MobileMenuProps & LanguageSwitcherProps;

export default function NavigationMenuMobile({
  menuItems,
  handleSelectItem,
  toggleHamburgerMenu,
  currentLanguage,
  translations,
}: NavigationMenuMobileProps) {
  const [selectedActive, setSelectedActive] = useState('');

  return (
    <div className="mobile-nav">
      <div className="mobile-nav__container">
        <div className="mobile-nav__menu-header">
          <div className="mobile-nav__menu-header-container">
            Menu
            <button
              type="button"
              onClick={toggleHamburgerMenu}
              className="close-icon-wrapper"
              aria-label="Close Menu"
            >
              <CloseIcon title="Close Menu" className="close-icon" />
            </button>
          </div>
        </div>
        <section aria-label="Navigation Menu" className="mobile-nav__links">
          {menuItems?.map((menuItem, index) => {
            const menuItems = menuItem.links.length >= 1 ? true : false;
            if (menuItems) {
              return (
                <ul className="mobile-nav__link" key={index}>
                  <button
                    data-testid="main-nav-link"
                    onClick={() => {
                      setSelectedActive(handleSelectItem(menuItem.label, index, selectedActive));
                    }}
                    className={
                      'mobile-nav__nav-button' +
                      (selectedActive === menuItem.label ? ' active' : '')
                    }
                    aria-expanded={selectedActive === menuItem.label}
                    aria-label={menuItem.label}
                  >
                    {menuItem.label}
                  </button>
                  <div
                    className={
                      'mobile-nav__sub-menu' +
                      (selectedActive === menuItem.label ? '' : ' menu-closed')
                    }
                  >
                    <div className="mobile-nav__sub-link-container">
                      {menuItem.links?.map((item, index) => (
                        <li key={index} className="mobile-nav__subheading-links">
                          {item.links?.length === 0 ? (
                            <Link to={item.url.translate.path}>{item.label}</Link>
                          ) : (
                            <ul key={index}>
                              <h4 className="mobile-nav__subheading">{item.label}</h4>

                              {item.links?.map((itemLinks, index) => (
                                <li key={index} className="mobile-nav__subheading-links">
                                  <Link to={itemLinks.url.translate.path}>{itemLinks.label}</Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </div>
                  </div>
                </ul>
              );
            } else {
              return (
                <ul className="mobile-nav__link margin" key={index}>
                  <Link
                    to={menuItem.url.translate.path}
                    activeClassName={'active'}
                    data-testid="main-nav-link"
                    className={
                      'mobile-nav__nav-button' +
                      (selectedActive === menuItem.label ? ' active' : '')
                    }
                    aria-expanded={selectedActive === menuItem.label}
                    aria-label={menuItem.label}
                  >
                    {menuItem.label}
                  </Link>
                </ul>
              );
            }
          })}
        </section>

        <section>
          <LanguageSwitcherMobile {...{ currentLanguage, translations }} />
        </section>
      </div>
    </div>
  );
}
