import React, { useState } from 'react';

import { Link } from '../Interfaces/Generics';

//Components
import FormContainer from '../ContactUs/Form/FormContainer';
import FormRelatedLinks from '../ContactUs/Form/FormRelatedLinks';
import FormOnThisPageSidebar from '../ContactUs/Form/FormOnThisPageSidebar';

//Typescript Interfaces
interface FormContainerProps {
  bodyText: string;
  relatedLinks: Link[];
  sidebarAnchors: {
    title: string;
    url: string;
  }[];
  currentLanguage: string;
  formInformation: {
    header: string;
    description: string;
  }[];
  formSuccess: {
    header: string;
    description: string;
  };
}

export default function ContactUsContainer({
  sidebarAnchors,
  formInformation,
  currentLanguage,
  relatedLinks,
  bodyText,
  formSuccess,
}: FormContainerProps) {
  const [hideSidebar, setHideSidebar] = useState<boolean>(false);
  return (
    <>
      <div className="w-1/3">
        <div className="child-sidebar-sticky">
          {hideSidebar ? null : (
            <>
              {sidebarAnchors.length > 1 && (
                <FormOnThisPageSidebar currentLanguage={currentLanguage} links={sidebarAnchors} />
              )}
            </>
          )}
          {relatedLinks.length > 0 && (
            <FormRelatedLinks
              currentLanguage={currentLanguage}
              className="related-links__visible"
              links={relatedLinks}
            />
          )}
        </div>
      </div>

      <div className="w-2/3">
        <FormContainer
          bodyText={bodyText}
          currentLanguage={currentLanguage}
          formInformation={formInformation}
          setHideSidebar={setHideSidebar}
          formSuccess={formSuccess}
        />
      </div>

      <>
        {relatedLinks.length > 0 && (
          <FormRelatedLinks
            currentLanguage={currentLanguage}
            className="related-links__not-visible"
            links={relatedLinks}
          />
        )}
      </>
    </>
  );
}
