import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HomePageData, HomeProps } from '../fragments/homepage-node';
import HomePageBody from '../components/Homepage/HomePageBody';

export default function Home() {
  const homeQuery = useStaticQuery<HomePageData>(graphql`
    query {
      drupal {
        pages: nodeQuery(
          filter: { conditions: [{ field: "type", value: "home_page", operator: EQUAL }] }
        ) {
          entities(language: EN) {
            ...TexasNode
            ...HomePageNode
          }
        }
      }
    }
  `);

  const data: HomeProps = homeQuery.drupal.pages.entities[0];

  return <HomePageBody {...data} />;
}
