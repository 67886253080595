import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/Layout';

import { MenuItemsProps } from '../sitemap';

import './../sitemap.scss';
import Breadcrumbs from '../../components/Generics/Breadcrumbs';
import { defaultBreadcrumbSpanish, TranslatedEntity } from '../../fragments/texas-node';

export default function SiteMapSpanish() {
  const data = useStaticQuery<MenuItemsProps>(graphql`
    query {
      drupal {
        menuByName(name: "sitemap-spanish", language: ES) {
          links {
            label
            links {
              label
              url {
                path
              }
            }
          }
        }
      }
    }
  `);

  const links = data.drupal.menuByName.links;

  const translations: TranslatedEntity[] = [
    {
      entityLanguage: { id: 'en', name: 'English' },
      entityUrl: { path: '/sitemap' },
      entityId: '',
    },
    {
      entityLanguage: { id: 'es', name: 'Spanish' },
      entityUrl: { path: '/es/sitemap' },
      entityId: '',
    },
  ];

  function displaySitemapItems() {
    return links.map((header, index) => {
      return (
        <div key={index} className="sitemap__card w-1/3">
          <h4 className="sitemap__list-header">{header.label}</h4>
          <ul className="sitemap__list">
            {header.links.map((item, index) => {
              return (
                <li key={index} className="sitemap__list-item">
                  <a href={item.url.path}>{item.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  }

  return (
    <Layout currentLanguage="es" translations={translations}>
      <section className="wrapper wrapper__child">
        <div className="container">
          <section className="w-2/3">
            <Breadcrumbs breadcrumb={defaultBreadcrumbSpanish} currentPageTitle="Mapa del Sitio" />
            <h1 className="sitemap__title">Mapa del Sitio</h1>
          </section>
        </div>
      </section>

      <section className="wrapper wrapper--white">
        <div className="container">{displaySitemapItems()}</div>
      </section>
    </Layout>
  );
}
