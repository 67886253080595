import React from 'react';
import { defaultBreadcrumb, TranslatedEntity } from '../fragments/texas-node';
import Layout from '../components/Layout';
import Search from '../components/Search/Search';
import SEO from '../components/SEO';

export default function SearchPage() {
  const entityTranslations: TranslatedEntity[] = [
    {
      entityLanguage: { id: 'en', name: 'English' },
      entityUrl: {
        path: '/search',
      },
      entityId: '',
    },
    {
      entityLanguage: { id: 'es', name: 'Spanish' },
      entityUrl: {
        path: '/es/search',
      },
      entityId: '',
    },
  ];

  const currentPath = '/search';

  return (
    <Layout currentLanguage="en" translations={entityTranslations}>
      <SEO
        metatags={[
          {
            key: 'title',
            value: 'Search Results',
          },
        ]}
        language="es"
        breadCrumbInfo={{
          breadcrumb: defaultBreadcrumb,
          currentPath: currentPath,
          currentTitle: 'Search Results',
        }}
        entityTranslations={entityTranslations}
      >
        <meta name="robots" content="noindex"></meta>
      </SEO>
      <Search currentLanguage="en" currentPath={currentPath} />
    </Layout>
  );
}
