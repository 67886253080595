import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { search, SearchResponse } from '../../search';

import './ServiceCard.scss';
import './ServicesDirectory.scss';

import ServiceCard from './ServiceCard';
import CheckMarkIcon from '../Icons/CheckMarkIcon';
import CloseIcon from '../Icons/CloseIcon';
import Pagination from '../Generics/Pagination';
import SearchBox from '../Generics/SearchBox';
import {
  translateLanguage,
  displaySearchMessage,
  displayFilterMessage,
} from '../utils/UtilityFunctions';
import { Service } from 'src/templates/Services';
import LoadingSpinner from '../Generics/LoadingSpinner';
import { LunrQueryOpts } from '../../lunr-query';

interface ServicesCtx {
  language: string;
  pageNumber: number;
  limit: number;
  totalItems: number;
  path: string;
}

interface ServiceDirectoryProps {
  cards: Service[];
  filter: ServiceTypeProps[];
  pageContext: ServicesCtx;
  currentLanguage: string;
}

interface ServiceTypeProps {
  entityTranslation: {
    entityId: string;
    entityLabel: string;
    serviceOfType: {
      entities: {
        entityTranslation: {
          entityLabel: string;
        };
      }[];
    };
  };
}

export default function ServicesDirectory({
  cards,
  filter,
  pageContext,
  currentLanguage,
}: ServiceDirectoryProps) {
  const [filterSelected, setFilterSelected] = useState<string[]>([]);
  const [filteredCards, setFilteredCards] = useState<any[]>(cards);
  const [showFilter, setShowFilter] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [totalResults, setTotalResults] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, isLoading] = useState<boolean>(false);

  const language = pageContext.language.toLowerCase();

  const resultsStartRef = useRef() as MutableRefObject<HTMLDivElement>;

  const searchQuery: LunrQueryOpts[] = [
    { field: 'nodeType', query: 'Drupal_TaxonomyTermServices', required: true },
  ];

  if (searchTerm) {
    searchQuery.push({ query: searchTerm, required: true });
  }

  function runSearch(currentPage?: number): Promise<SearchResponse> {
    return search(currentLanguage, searchQuery, {
      pageSize: pageContext.limit,
      currentPage: currentPage || pageNumber,
      filters: filterSelected,
    });
  }

  useEffect(() => {
    if (!searchTerm && !filterSelected.length) {
      setFilteredCards(cards);
    } else {
      isLoading(true);
      runSearch(1).then((res) => {
        isLoading(false);
        setTotalResults(res.totalResults);
        setFilteredCards(res.results);
        setPageNumber(1);
      });
    }
  }, [filterSelected, searchTerm]);

  const filterItems = filter.filter((e) => !!e.entityTranslation);
  filterItems.sort((a, b) =>
    a.entityTranslation.entityLabel > b.entityTranslation.entityLabel ? 1 : -1
  );

  function handleFilterChange(filterType: string) {
    if (filterSelected.includes(filterType)) {
      setFilterSelected(filterSelected.filter((type) => type !== filterType));
    } else {
      setFilterSelected((filter) => [...filter, filterType]);
    }
  }

  function handleCloseFilter(filterType: string) {
    setFilterSelected(filterSelected.filter((type) => type !== filterType));
  }

  function handleClearFilter() {
    setFilterSelected([]);
  }

  const getSearchTerm = (term: string) => {
    setSearchTerm(term);
  };

  const usePagination = (n: number) => {
    runSearch(n)?.then((res) => {
      setPageNumber(n);
      setFilteredCards(res.results);
      setTotalResults(res.totalResults);
      if (resultsStartRef && resultsStartRef.current) {
        resultsStartRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  };

  return (
    <section
      className="wrapper wrapper--another-grey services-wrapper"
      id="results-start"
      ref={resultsStartRef}
    >
      <div className="container">
        <div className="w-1/3 d-none-mobile">
          <div className="services-dir__filter-container">
            <div className="services-dir__filter-title">
              {translateLanguage(language, 'Filter service type', 'Filtra for tipo de servicio')}
            </div>

            {filterItems.map((item, i) => {
              return (
                <div key={i} className="services-dir__filter">
                  <div
                    className={
                      'services-dir__checkbox-container' +
                      (filterSelected.includes(item.entityTranslation.entityId) ? ' checked' : '')
                    }
                  >
                    {filterSelected.includes(item.entityTranslation.entityId) && (
                      <CheckMarkIcon className="check" />
                    )}
                    <input
                      className="services-dir__checkbox"
                      type="checkbox"
                      name={item.entityTranslation.entityLabel}
                      checked={filterSelected.includes(item.entityTranslation.entityId)}
                      onChange={() => handleFilterChange(item.entityTranslation.entityId)}
                      id={item.entityTranslation.entityLabel}
                      data-testid="filter-checkbox"
                    />
                  </div>

                  <label
                    className={
                      'services-dir__filter-type' +
                      (filterSelected.includes(item.entityTranslation.entityId) ? ' selected' : '')
                    }
                    htmlFor={item.entityTranslation.entityLabel}
                  >
                    {item.entityTranslation.entityLabel}
                  </label>
                </div>
              );
            })}

            <button
              className="services-dir__clear-filter"
              onClick={() => handleClearFilter()}
              aria-label={translateLanguage(
                language,
                'Clear all filters',
                'Borrar todos los filtros'
              )}
            >
              {translateLanguage(language, 'Clear all filters', 'Borrar todos los filtros')}
            </button>
          </div>
        </div>

        <div className="w-2/3">
          <div className="services-dir__input-section">
            <SearchBox
              pageContextLanguage={pageContext.language}
              placeholder={translateLanguage(
                language,
                'Search for government services',
                'Buscar servicios gubernamentales'
              )}
              onSearch={getSearchTerm}
              language={language}
            />
            {showFilter && (
              <div className="services-dir__mobile-filter">
                <div className="services-dir__filter-title">Filter service type: </div>

                {filterItems.map((item, i) => {
                  return (
                    <div key={i} className="services-dir__filter">
                      <div
                        className={
                          'services-dir__checkbox-container' +
                          (filterSelected.includes(item.entityTranslation.entityId)
                            ? ' checked'
                            : '')
                        }
                      >
                        {filterSelected.includes(item.entityTranslation.entityId) && (
                          <CheckMarkIcon className="check" />
                        )}
                        <input
                          className="services-dir__checkbox"
                          type="checkbox"
                          checked={filterSelected.includes(item.entityTranslation.entityId)}
                          onChange={() => handleFilterChange(item.entityTranslation.entityId)}
                          name={item.entityTranslation.entityLabel}
                          id={item.entityTranslation.entityLabel}
                          data-testid="mobile-filter-checkbox"
                        />
                      </div>

                      <label
                        className={
                          'services-dir__filter-type' +
                          (filterSelected.includes(item.entityTranslation.entityId)
                            ? ' selected'
                            : '')
                        }
                        htmlFor={item.entityTranslation.entityLabel}
                      >
                        {item.entityTranslation.entityLabel}
                      </label>
                    </div>
                  );
                })}

                <button
                  className="services-dir__clear-filter"
                  onClick={() => handleClearFilter()}
                  aria-label={translateLanguage(
                    language,
                    'Clear all filters',
                    'Borrar todos los filtros'
                  )}
                  data-testid="mobile-clear-all-filters"
                >
                  {translateLanguage(language, 'Clear all filters', 'Borrar todos los filtros')}
                </button>

                <button
                  className="services-dir__open-close-filter"
                  onClick={() => setShowFilter(!showFilter)}
                  aria-label="Close"
                  data-testid="mobile-close-filters"
                >
                  Close
                </button>
              </div>
            )}

            {!showFilter && (
              <button
                className="services-dir__open-close-filter"
                onClick={() => setShowFilter(!showFilter)}
                aria-label="Open filters"
                data-testid="mobile-filters"
              >
                Open filters
              </button>
            )}
            <hr className="services-dir__divider" />
          </div>

          {(filterSelected.length > 0 || searchTerm) && (
            <div className="services-dir__filter-results">
              <div className="services-dir__display-message">
                {searchTerm && <h3>{displaySearchMessage(totalResults, searchTerm, language)}</h3>}
                {filterSelected.length > 0 && !searchTerm && (
                  <h3>{displayFilterMessage(totalResults, language)}</h3>
                )}
                {filteredCards.length === 0 && (
                  <p>
                    {translateLanguage(
                      language,
                      'Try clearing your filters or changing your search.',
                      'Intenta borrar filtros de busqueda, o cambiar una búsqueda nueva.'
                    )}
                  </p>
                )}
              </div>
              {filterSelected.length > 0 && (
                <div className="services-dir__filter-by">
                  <div className="services-dir__filter-by-title">Filtering by:</div>

                  {filterSelected.map((filter, i) => (
                    <div key={i} className="services-dir__filter-by-box">
                      {
                        filterItems.find((id) => id.entityTranslation.entityId === filter)
                          ?.entityTranslation.entityLabel
                      }
                      <button
                        type="button"
                        onClick={() => handleCloseFilter(filter)}
                        className="close-icon-wrapper"
                        aria-label="Close"
                        data-testid="clear-filter"
                      >
                        <CloseIcon title="Clear Search" className="close-icon" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {filteredCards.map((card, i) => {
            let cardTranslation = card;
            if (card.entityTranslation) {
              cardTranslation = card.entityTranslation;
            }
            const agencies = cardTranslation.agencyOfService?.entities
              .filter((entity: any) => !!entity.entityTranslation)
              .map((entity: any) => entity.entityTranslation.entityLabel);

            return (
              <ServiceCard
                key={i}
                title={cardTranslation.entityLabel}
                path={cardTranslation.fieldServiceLink.url.path}
                agencies={agencies}
                currentLanguage={language}
              />
            );
          })}
          {filterSelected.length < 1 && !searchTerm ? (
            <Pagination
              currentLanguage={language}
              totalItems={pageContext.totalItems}
              pageSize={pageContext.limit}
              currentPage={pageContext.pageNumber}
              createLinkUrl={(n) =>
                n === 1
                  ? `${pageContext.path}#results-start`
                  : `${pageContext.path}${n}/#results-start`
              }
            />
          ) : (
            <Pagination
              currentLanguage={language}
              totalItems={totalResults}
              pageSize={pageContext.limit}
              currentPage={pageNumber}
              createLinkUrl={() => ''}
              onClick={usePagination}
            />
          )}
        </div>
      </div>
      {loading && <LoadingSpinner />}
    </section>
  );
}
