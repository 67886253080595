import React from 'react';
import xss from 'xss';

import './FormBodyText.scss';

interface FormBodyTextProps {
  bodyText: string;
}

export default function FormBodyText(props: FormBodyTextProps) {
  return (
    <div
      className="form-body-text"
      dangerouslySetInnerHTML={{
        __html: xss(props.bodyText),
      }}
    />
  );
}
