import React from 'react';

interface IconProps {
  className?: string;
}

export default function HomeExploreIcon(props: IconProps) {
  return (
    <svg {...props} role="img" viewBox="0 0 24 23" className={props.className}>
      <title>home-explore</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-894.000000, -1717.000000)" fill="#E96564" fillRule="nonzero">
          <g id="Home-Page-Icons" transform="translate(64.000000, 1561.000000)">
            <g id="home-explore" transform="translate(830.000000, 156.000000)">
              <path
                d="M14.970048,22.758768 L14.970048,3.937536 L9.41256,0.54 C9.22128,0.416256 8.996256,0.337536 8.782512,0.281232 L8.782512,19.36128 L14.45256,22.533744 C14.632512,22.635024 14.80128,22.702512 14.970048,22.758768 Z M1.57128,22.42128 C1.863792,22.42128 2.145024,22.33128 2.516256,22.128768 L7.24128,19.586256 L7.24128,0.483744 C7.038768,0.585024 6.825024,0.697536 6.61128,0.82128 L1.278768,3.87 C0.603792,4.252512 0.288768,4.792512 0.288768,5.546256 L0.288768,21.127488 C0.288768,21.96 0.77256,22.42128 1.57128,22.42128 Z M16.51128,22.736256 C16.623792,22.702512 16.736304,22.657536 16.848768,22.578768 L22.732464,19.237488 C23.396256,18.866256 23.711232,18.326256 23.711232,17.572512 L23.711232,1.968768 C23.711232,1.136256 23.238912,0.686256 22.428816,0.686256 C22.147488,0.686256 21.85512,0.765024 21.494976,0.967536 L16.51128,3.735024 L16.51128,22.736256 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
