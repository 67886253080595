import React, { useState } from 'react';
import Img from 'gatsby-image';
import { ChildImageSharpType } from '../Interfaces/Generics';

import './GetToKnow.scss';

interface GetToKnowProps {
  heading: string;
  sub_heading: string;
  description: string;
  imageSrc: {
    alt: string;
    title: string;
    entity: {
      localFile: ChildImageSharpType;
    };
  }[];
}

export default function GetToKnow(props: GetToKnowProps) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const skipId = props.heading.replace(/\W/g, '-');

  return (
    <div className="get-to-know__single">
      <div className="w-1/2 get-to-know__information">
        <h2>{props.heading}</h2>
        <h3>{props.sub_heading}</h3>
        <p>{props.description}</p>
      </div>

      <div className="w-1/2 get-to-know__images">
        {props.imageSrc.length && (
          <Img
            className="get-to-know__selected-img"
            fluid={props.imageSrc[selectedImageIndex].entity.localFile.childImageSharp.fluid}
            alt={props.imageSrc[selectedImageIndex].alt}
          />
        )}

        <a href={`#${skipId}`} className="visually-hidden focusable">
          Skip image carousel
        </a>

        <div className="get-to-know__thumbnail-wrapper">
          {props.imageSrc.map((item, i) => (
            <button
              key={i}
              className="get-to-know__thumbnail-button"
              onClick={() => setSelectedImageIndex(i)}
              aria-label="Select and enlarge photo"
            >
              <Img
                className={'get-to-know__thumbnail' + (i === selectedImageIndex ? ' selected' : '')}
                fluid={item.entity.localFile.childImageSharp.fluid}
                alt={props.imageSrc[i].alt}
              />
            </button>
          ))}
          <span id={skipId}></span>
        </div>
      </div>
    </div>
  );
}
