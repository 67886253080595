import React from 'react';

interface IconProps {
  className?: string;
}

export default function CheckMarkIcon(props: IconProps) {
  return (
    <svg
      {...props}
      aria-labelledby="check-mark-icon"
      role="img"
      width="18px"
      height="18px"
      viewBox="0 0 24 24"
      className={props.className}
    >
      <title id="check-mark-icon">Check Mark Icon</title>
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
}
